import styled from 'styled-components'
import { Link } from 'react-router-dom'


export const iconsCSS = {

    color: `${props => props.borderButtonMenu} !important`,
};

export const Container = styled.div `
width: 100%;
height: 100vh;
margin: 0;
padding: 0%;
background-color: #043D5D;
display: flex;
justify-content: center;
align-items: center;
background-image: url(${props => props.bg});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
`;

export const Title = styled.div `
color: #ddd;
font-weight: bold;
`;

export const WrapEsqueciSenha = styled.div `
width: 64%;
display: flex;
justify-content: flex-end;
/* background:red; */
align-items: flex-end;
@media(max-width: 600px){
width: 80%;
}

`;

export const ButtonEsqueciSenha = styled(Link)
`
width: 100px;
cursor: pointer;
color: #ddd;
font-weight: 500;
`;

export const Logo = styled.div `
display:flex;
width: 90%;
height: 45%;
align-items: center;
justify-content: center;
background-image: url(${props => props.img});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
padding: 0;
margin: 0;
@media(max-width: 1920px){
width: 80%;
height: 70%;
}


@media(max-width: 600px){
width: 310px;
height: 58px;
}

`;



export const Form = styled.div `
width: 100%;
max-width: 600px;
height: 500px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
position: fixed;
top: 4%;
left: 50;
right: 50;
bottom: 50%;
border-radius: 10px;
transform: ${props => props.showForm};
transition: transform 0.5s ease-out;
padding-bottom: 10px;
`;

export const ButtonLogin = styled.button `
outline: 0;
border: none;
text-decoration: none;
background-color: #107CB8;
color: #ddd;
height: 40px;
width: 30%;
display: flex;
align-items: center;
justify-content: center;
border-radius: 10px;
&:hover{
  text-decoration: none;
  color: #ddd;
  opacity: 0.6;
}
@media (max-width: 1920px) {
 height: 35px;
 width: 30%;
}
`;
export const InputLoginParent = styled.div `
  display: flex;
  position:relative;
  width:65%;
  @media (max-width: 600px) {
width: 85%;
}
`;

export const InputLogin = styled.input `
width: 100%;
margin: 0 10px;
height: 50px;
outline: 0;
border: none;
box-shadow: none;
border-radius: 5px;
color: gray;
padding-left: 30px;
@media (max-width: 600px) {
width: 100%;
}
`;
/* 
AiFillEyeInvisible,
AiFillEye
*/
export const AiFillEyeInvisible = styled.svg `
  opacity: 0
`;
export const FormLogo = styled.a `
outline:0 ;
box-shadow: none;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 90%;
height: 100px;
padding: 0;

`;
export const FormInputs = styled.form `
width: 90%;
height: 60%;
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: column;
border: 1px gray solid;
border-radius: 10px;
@media (max-width: 600px) {
 height: 60%;
 width: 95%;
}

`;