import React, { useState, createContext, useContext } from 'react'

const SharedContext = createContext()

export default function SharedProvider({ children }) {
    const [showModalPessoa, setShowModalPessoa] = useState({ show: false, title: '', action: '', item: '' });
    const [showModalEndereco, setShowModalEndereco] = useState({ show: false, title: '', action: '', item: '' });
    const [showModalEstudo, setShowModalEstudo] = useState({ show: false, title: '', action: '', item: '' });
    const [showModalTurma, setShowModalTurma] = useState({ show: false, title: '', action: '', item: '' });
    const [showModalArea, setShowModalArea] = useState({ show: false, title: '', action: '', item: '' });
    const [showModalAlunosTurma, setShowModalAlunosTurma] = useState({ show: false, title: '', action: '', item: '' });
    const [showModalGeral, setShowModalGeral] = useState({ show: false, title: '', action: '', item: '' });
    const [showModalInclusao, setShowModalInclusao] = useState({ show: false, title: '', action: '', item: '' });
    const [showModalTemaEstudo, setShowModalTemaEstudo] = useState({ show: false, title: '', action: '', item: '' });


    return <SharedContext.Provider
        value={
            {
                setShowModalPessoa,
                showModalPessoa,
                setShowModalEndereco,
                showModalEndereco,
                setShowModalEstudo,
                showModalEstudo,
                setShowModalTurma,
                showModalTurma,
                setShowModalArea,
                showModalArea,
                setShowModalAlunosTurma,
                showModalAlunosTurma,
                setShowModalGeral,
                showModalGeral,
                setShowModalInclusao,
                showModalInclusao,
                showModalTemaEstudo,
                setShowModalTemaEstudo
            }
        } > {children} </SharedContext.Provider>
}


export function useShowModal() {
    const { setShowModalPessoa,
        showModalPessoa,
        setShowModalEndereco,
        showModalEndereco,
        setShowModalEstudo,
        showModalEstudo,
        setShowModalTurma,
        showModalTurma,
        setShowModalArea,
        showModalArea,
        setShowModalAlunosTurma,
        showModalAlunosTurma,
        setShowModalGeral,
        showModalGeral,
        setShowModalInclusao,
        showModalInclusao,
        setShowModalTemaEstudo,
        showModalTemaEstudo
    } = useContext(SharedContext)
    return {
        setShowModalPessoa,
        showModalPessoa,
        setShowModalEndereco,
        showModalEndereco,
        setShowModalEstudo,
        showModalEstudo,
        setShowModalTurma,
        showModalTurma,
        setShowModalArea,
        showModalArea,
        setShowModalAlunosTurma,
        showModalAlunosTurma,
        setShowModalGeral,
        showModalGeral,
        setShowModalInclusao,
        showModalInclusao,
        showModalTemaEstudo,
        setShowModalTemaEstudo
    }
}

export const overrideStrings = {
    "selectSomeItems": "Opções...",
    "allItemsAreSelected": "Todos itens Selecionados",
    "selectAll": "Selecionar Todos",
    "search": "Pesquisar"
}

export const overrideStringsSearch = {
    "selectSomeItems": "Opções...",
    "allItemsAreSelected": "Todos itens Selecionados",
    "selectAll": "Selecionar Todos",
    "search": "Pesquisar"
}
