import React, { useState, useContext, useEffect } from 'react';
import { Wrap, WrapInput, WrapList } from '../../components/Wrap';
import { Input, LabelInput, inputStyle,multSelect } from '../../components/Input';
import { SearchBox, IconeSearch, ItemsSearch } from "../../components/Search";
import { Button, CancelarBtn } from "../../components/Button";
import { Container, TitleStyle2,Items} from './styles'
import { HeaderList, BodyList } from '../../components/TabelaCadastro';
import { Modal, CircularProgress } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '../../components/SharedComponents/table';
import { shade } from 'polished'
import { AiOutlineDelete, AiOutlineEdit,AiOutlineTeam,AiTwotoneDelete} from "react-icons/ai";
import { ThemeContext } from 'styled-components';
import { ERRORMESSAGE, SUCCESSMESSAGE, INFOMESSAGE } from '../../utils/types-messages';
import { useSnackbar } from 'notistack';
import { api } from '../../services/axios';
import { makeStyles } from '@material-ui/core/styles';
import { modalClose, ModalBox, StyledBox } from '../../components/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import MessageAlert from '../../components/MessageAlert';
import { useShowModal } from '../../contexts/SharedContext'
import InputMask from 'react-input-mask';
import { MySelect } from "../../components/InputSelect";
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import { tint } from "polished";
import Header from "../../components/Header"


export default function CadastroTurmas(props){
    const { enqueueSnackbar } = useSnackbar();
    const { colors, textTableColor } = useContext(ThemeContext);
    const [turmas, setTurmas] = useState([]);
    const [codigoConsulta, setCodigoConsulta] = useState('');
    const [tipoConsulta, setTipoConsulta] = useState('');
    const [anoConsulta, setAnoConsulta] = useState('');
    const [codigo, setCodigo] = useState('');
    const [capacidade, setCapacidade] = useState('');
    const [local, setLocal] = useState('');
    const [loadDialog, setLoadDialog] = useState(false);
    const [idToModal, setIdToModal] = useState();
    const { setShowModalTurma, showModalTurma } = useShowModal();
    const { setShowModalAlunosTurma, showModalAlunosTurma } = useShowModal()
    const [tipo, setTipo] = useState(null);
    const [dataInicio,setDataInicio] = useState('');
    const [dataFim,setDataFim] = useState('');
    const [estudoOptions, setEstudoOptions] = useState([]);
    const [estudo, setEstudo] = useState(null);
    const [pessoaOptions, setPessoaOptions] = useState([]);
    const [monitor, setMonitor] = useState(null);
    const [instrutor, setInstrutor] = useState(null);
    const [estudoConsulta, setEstudoConsulta] = useState(null);
    const [alunosOptions, setAlunosOptions] = useState([]);
    const [aluno, setAluno] = useState(null);
    const [tableAlunos, setTableAlunos] = useState([]);
    const [idTurma, setIdTurma] = useState();

    useEffect(() => {
      getEstudos();
    }, [])

    const useStyles = makeStyles({
        wrapBtnModal: {
          alignItems: 'flex-start'
        },
        containerModal: {
          boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
          borderRadius: '0'
        },
        headColModal: {
          backgroundColor: colors.surface,
          textTransform: 'uppercase',
          borderRadius: '0',
          fontSize: '14px'
        }
    });
    const classes = useStyles();

    const tipoOptions = [
      { value: "Presencial", label: "Presencial" },
      { value: "On-line", label: "On-line" },
      { value: "Híbrida", label: "Híbrida" },
      { value: "EAD", label: "EAD" }
    ]

    const handleTipoIncluir = (item) => {
      setTipo(item);
    }

    const handleTipoConsulta = (item) => {
      setTipoConsulta(item);
    }

    const handleEstudoIncluir = (item) => {
      setEstudo(item)
    }

    const handleEstudoConsulta = (item) => {
      setEstudoConsulta(item)
    }

    const handleMonitorIncluir = (item) => {
      setMonitor(item)
    }

    const handleInstrutorIncluir = (item) => {
      setInstrutor(item)
    }

    const handleAlunoIncluir = (item) => {
      setAluno(item)
    }

    const getEstudos = async () => {
    try {
      setLoadDialog(true);
      const { data } = await api.get('/estudo');
      setEstudoOptions(data);
      setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar("Erro ao buscar dados iniciais", ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  const getPessoas = async () => {
    try {
      setLoadDialog(true);
      const { data } = await api.get('/pessoa?status=A');
      setPessoaOptions(data);
      setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar("Erro ao buscar dados iniciais", ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  const getPessoasParaTurma = async (idTurma) => {
    try {
      setLoadDialog(true);
      const { data } = await api.get('/pessoaturma/pessoasparaturma/'+idTurma);
      setAlunosOptions(data);
      setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar("Erro ao buscar dados iniciais", ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  async function getAlunosTurma(idTurma) {
    //const key = enqueueSnackbar('Pesquisando Pessoas ...', INFOMESSAGE)
    try {
      const { data } = await api.get("/pessoaturma/" + idTurma);
      if (data) {
        setTableAlunos(data);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar alunos", ERRORMESSAGE);
    }
  }


    async function fetchSearch(showSnack=true) {
        if (showSnack) {const key = enqueueSnackbar('Pesquisando Turmas ...', INFOMESSAGE);};
        try {
            let params = ''
            if (codigoConsulta !== '') params += `&codigo=${codigoConsulta}`
            if (anoConsulta !== '') params += `&ano=${anoConsulta}`
            if ((tipoConsulta !== null) && (tipoConsulta.value !== undefined) && (tipoConsulta.value !== '')){
              params += `&tipo=${tipoConsulta.value}`
            }
            if ((estudoConsulta !== null) && (estudoConsulta.value !== undefined) && (estudoConsulta.value !== '')){
              params += `&idEstudo=${estudoConsulta.value}`
            } 
            setLoadDialog(true);
            const { data } = await api.get(`turma?` + params);
            setTurmas(data);
            setLoadDialog(false);
        } catch (error) {
            enqueueSnackbar("Erro ao buscar Turmas", ERRORMESSAGE);
            setLoadDialog(false);
        }
    }

    function clearSearch() {
        setTurmas([]);
        setCodigoConsulta('');
        setTipoConsulta([]);
        setAnoConsulta('');
        setEstudoConsulta([]);
    }

    function openModal(action, item) {
        clearInclude();
        getPessoas();
        if (action === "insert") {
            setShowModalTurma({ show: true, title: 'INCLUIR TURMA', action: 'inserir' });
        }
        else {
            setShowModalTurma({ show: true, title: 'ALTERAR TURMA', action: 'editar', item: item });
        }
            
    }

    function openModalAlunosTurma(item) {
        //clearInclude();
      getPessoasParaTurma(item.id);
      getAlunosTurma(item.id);
      setIdTurma(item.id)
      setShowModalAlunosTurma({ show: true, title: 'ALUNOS DA TURMA', action: 'editar', item: item });          
    }

    function clearInclude() {
        setCodigo(null);
        setLocal(null);
        setCapacidade(null);
        setDataInicio(null);
        setDataFim(null);
        setMonitor([]);
        setInstrutor([]);
        setEstudo([]);
        setTipo([]);
        
    }

    const handleClose = () => {
      setShowModalTurma({ show: false });
    };

    const handleCloseAlunosTurma = () => {
      setShowModalAlunosTurma({ show: false });
    };

    async function sendTurma() {

        const key = enqueueSnackbar('Cadastrando Turma ...', INFOMESSAGE);
        
        try {
    
          const valoresInseridos = {
            codigo: codigo,
            dataInicio: dataInicio,
            dataFim: dataFim,
            capacidade: capacidade,
            tipo: tipo.value,            
            local: local,
            idEstudo: estudo.value,
            idInstrutor: instrutor.value,
            idMonitor: monitor.value
          };
    
          setLoadDialog(true);
          const { data } = await api.post("/turma/", valoresInseridos);
          if (data.message!=null) throw {message: data.message}
          enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
          setShowModalTurma({ show: false });
          clearInclude();
          fetchSearch(false);
          setLoadDialog(false);
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
          setLoadDialog(false);
        }
      };
    
      async function editTurma(id) {
    
        const key = enqueueSnackbar('Alterando Turma ...', INFOMESSAGE);
    
        try {
    
          const valoresEditados = {
            codigo: codigo,
            dataInicio: dataInicio,
            dataFim: dataFim,
            capacidade: capacidade,
            tipo: tipo.value,            
            local: local,
            idEstudo: estudo.value,
            idInstrutor: instrutor.value,
            idMonitor: monitor.value
          };
    
          setLoadDialog(true);
          const { data } = await api.put("/turma/" + id, valoresEditados);
          if (data.message!=null) throw {message: data.message}
          enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
          setShowModalTurma({ show: false });
          clearInclude();
          fetchSearch(false);
          setLoadDialog(false);
    
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
          setLoadDialog(false);
        }
      };

      useEffect(() => {
        if (showModalTurma.action === 'editar') {
            setIdToModal(showModalTurma.item.id);
            setCodigo(showModalTurma.item.codigo);
            setLocal(showModalTurma.item.local);
            setCapacidade(showModalTurma.item.capacidade);
            setDataInicio(showModalTurma.item.dataInicio + 'T00:00:00');
            if (showModalTurma.item.dataFim !== null) setDataFim(showModalTurma.item.dataFim + 'T00:00:00');
            setTipo({value:`${showModalTurma.item.tipo}`, label:`${showModalTurma.item.tipo}`});
            setEstudo({value:`${showModalTurma.item.idEstudo}`, label:`${showModalTurma.item.nomeEstudo}`});
            setMonitor({value:`${showModalTurma.item.idMonitor}`, label:`${showModalTurma.item.nomeMonitor}`});
            setInstrutor({value:`${showModalTurma.item.idInstrutor}`, label:`${showModalTurma.item.nomeInstrutor}`});
        }
      }, [showModalTurma.action])


    async function deleteTurma(id) {
        try {
          var resposta = window.confirm("Deseja remover esse registro?");
          if (resposta) {
            const key = enqueueSnackbar('Excluindo Turma ...', INFOMESSAGE);
            const { data } = await api.delete("/turma/" + id);
            if ((data.success !== undefined) && (!data.success))
            {
              throw new Error(data.message)
            }
            enqueueSnackbar(<MessageAlert message={"Turma excluída com sucesso!"} />, SUCCESSMESSAGE);
            fetchSearch(false);
          }
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
        }
    };

    async function deletePessoaTurma(idTurma,idPessoa) {
      try {
        var resposta = window.confirm("Deseja remover esse registro?");
        if (resposta) {
          const key = enqueueSnackbar('Excluindo Turma ...', INFOMESSAGE);
          const { data } = await api.delete("/pessoaturma/" + idTurma + "/" + idPessoa);
          if ((data.success !== undefined) && (!data.success))
          {
            throw new Error(data.message)
          }
          enqueueSnackbar(<MessageAlert message={"Aluno excluído da turma com sucesso!"} />, SUCCESSMESSAGE);
          fetchSearch(false);
          getPessoasParaTurma(idTurma);
          getAlunosTurma(idTurma);
        }
      } catch (err) {
        enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      }
  };

  async function savePessoaTurma() {

    const key = enqueueSnackbar('Cadastrando Aluno ...', INFOMESSAGE);
    
    try {

      const valoresInseridos = {
        idTurma: idTurma,
        idPessoa: aluno.value
      };

      setLoadDialog(true);
      const { data } = await api.post("/pessoaturma/", valoresInseridos);
      if (data.message!=null) throw {message: data.message}
      enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
      setAluno([]);
      getPessoasParaTurma(idTurma);
      getAlunosTurma(idTurma);
      setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
  };
    

    const bodyTurma = (
        <StyledBox className={'disableFocus alert-modal'}>
          <TitleStyle2>
            {showModalTurma.title}
            <CancelIcon
              style={modalClose}
              color={colors.error}
              size="20"
              title="Fechar"
              onClick={() => handleClose()}
            />
          </TitleStyle2>
          <ModalBox>
            <Wrap>
              <WrapInput style={{ position: 'relative', width: '10%'}}>
                <LabelInput>Código <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <Input
                  placeholder="Inserir Código"
                  style={inputStyle}
                  maxLength="25"
                  type="text"
                  name="codigo"
                  onChange={e => setCodigo(e.target.value.toLocaleUpperCase())}
                  value={codigo}
                />
              </WrapInput>
              <WrapInput style={{ position: 'relative', width: '20%'}}>
                <LabelInput>Tipo <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <MySelect
                  styles={multSelect}
                  placeholder={'Opções...'}
                  value={tipo}
                  isClearable={true}
                  isSearchable={true}
                  onChange={handleTipoIncluir}
                  options={tipoOptions}
                />
              </WrapInput>
              <WrapInput style={{ position: 'relative', width: '10%'}}>
                <LabelInput>Capacidade <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <InputMask
                  mask="99999"
                  maskChar=""
                  placeholder="Inserir Capacidade"
                  style={inputStyle}
                  type="text"
                  name="capacidade"
                  onChange={e => setCapacidade(e.target.value)}
                  value={capacidade}
                />
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput style={{ position: 'relative', width: '20%'}}>
                <LabelInput>Estudo <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <MySelect
                  styles={multSelect}
                  placeholder={'Opções...'}
                  value={estudo}
                  isClearable={true}
                  isSearchable={true}
                  onChange={handleEstudoIncluir}
                  options={estudoOptions.map((item) => { return { value: item.id, label: item.nome }; })}
                />
              </WrapInput>
              <WrapInput style={{ position: 'relative', width: '20%'}}>
                <LabelInput>Instrutor <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <MySelect
                  styles={multSelect}
                  placeholder={'Opções...'}
                  value={instrutor}
                  isClearable={true}
                  isSearchable={true}
                  onChange={handleInstrutorIncluir}
                  options={pessoaOptions.map((item) => { return { value: item.id, label: item.nome }; })}
                />
              </WrapInput>
              <WrapInput style={{ position: 'relative', width: '20%'}}>
                <LabelInput>Monitor <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <MySelect
                  styles={multSelect}
                  placeholder={'Opções...'}
                  value={monitor}
                  isClearable={true}
                  isSearchable={true}
                  onChange={handleMonitorIncluir}
                  options={pessoaOptions.map((item) => { return { value: item.id, label: item.nome }; })}
                />
              </WrapInput>
            </Wrap>
            <Wrap>           
              <WrapInput style={{ position: 'relative', width: '30%'}}>
                <LabelInput>Local <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <Input
                  placeholder="Inserir Local"
                  style={inputStyle}
                  maxLength="255"
                  type="text"
                  name="local"
                  onChange={e => setLocal(e.target.value)}
                  value={local}
                  multiple="true"
                />
              </WrapInput>
              <WrapInput style={{ position: 'relative', width: '30%', marginLeft: '0px' }}>
                <LabelInput style={{padding: '0.7px'}}>Data de Início <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                  <KeyboardDatePicker 
                    value={dataInicio} 
                    onChange={setDataInicio}
                    format="dd/MM/yyyy"
                    clearLabel="Limpar"
                    cancelLabel="Cancelar"
                    clearable
                    minDateMessage="Não é possível buscar por datas anteiores a 01/01/1900."
                    invalidDateMessage="A data inserida não é válida!"
                    helperText={""}
                    id="dataInicio"
                    margin="dense"
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </WrapInput>
              <WrapInput style={{ position: 'relative', width: '30%', marginLeft: '0px' }}>
                <LabelInput style={{padding: '0.7px'}}>Data de Fim </LabelInput>
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                  <KeyboardDatePicker 
                    value={dataFim} 
                    onChange={setDataFim}
                    format="dd/MM/yyyy"
                    clearLabel="Limpar"
                    cancelLabel="Cancelar"
                    clearable
                    minDateMessage="Não é possível buscar por datas anteiores a 01/01/1900."
                    invalidDateMessage="A data inserida não é válida!"
                    helperText={""}
                    id="dataFim"
                    margin="dense"
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput className="wrapInputModal">
                <Items>
                  <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
                  <Button type="button" onClick={() => showModalTurma.action === 'inserir' ? sendTurma() : editTurma(idToModal)} > Salvar </Button>
                </Items>
              </WrapInput>
            </Wrap>
          </ModalBox>
        </StyledBox>
      );

      const bodyAlunosTurma = (
        <StyledBox className={'disableFocus alert-modal'}>
          <TitleStyle2>
            {showModalAlunosTurma.title}
            <CancelIcon
              style={modalClose}
              color={colors.error}
              size="20"
              title="Fechar"
              onClick={() => handleCloseAlunosTurma()}
            />
          </TitleStyle2>
          <ModalBox>
            <Wrap>
              <WrapInput className="wrapInputModal">
                  <LabelInput>Pessoas <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                  <MySelect
                    styles={multSelect}
                    placeholder={'Opções...'}
                    value={aluno}
                    isClearable={true}
                    isSearchable={true}
                    onChange={handleAlunoIncluir}
                    options={alunosOptions.map((item) => { return { value: item.id, label: item.nome}; })}
                  />
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput className="wrapInputModal" >
                <Items style={{height: '16px'}}>
                  <CancelarBtn className='widthButton' type="button" onClick={handleCloseAlunosTurma}> Sair </CancelarBtn>
                  <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' type="button" onClick={() => savePessoaTurma()}>&nbsp;Adicionar<AddIcon></AddIcon></Button>
                </Items>
              </WrapInput>
            </Wrap>
            <WrapList>
              {
                <BodyList style={{ width: '93%', height: '35vh'}}>
                <TableContainer className={classes.containerModal}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.headColModal} align="center">Aluno</TableCell>
                        <TableCell className={classes.headColModal} align="center">Situação</TableCell>
                        <TableCell className={classes.headColModal} align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ width: '90%', backgroundColor: 'white' }}>
                      {
                        tableAlunos && tableAlunos.map((row, index) => {
                          return (
                            <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                              <TableCell align="center">
                                {row.nomeAluno}
                              </TableCell>
                              <TableCell align="center">
                                {row.situacao}
                              </TableCell>
                              <TableCell align="center">
                                <AiTwotoneDelete
                                  style={{ cursor: "pointer", marginLeft: "5px" }}
                                  color={tint(0.1, colors.error)}
                                  size="20"
                                  title="Remover"
                                  onClick={() => deletePessoaTurma(row.idTurma,row.idPessoa)}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                </BodyList>
                }
            </WrapList>
          </ModalBox>
        </StyledBox>
      );
    

    const modalLoad = (
        <div className={'disabeFocus alert-modal'}>
            <WrapInput className="alertText" style={{ alignItems: 'center' }}>
            <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
            </WrapInput>
        </div>
    );

    return (
        <Container>
          <Header/>
            <Modal open={showModalTurma.show}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
            {bodyTurma}
            </Modal>
            <Modal open={showModalAlunosTurma.show}
                onClose={handleCloseAlunosTurma}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
            {bodyAlunosTurma}
            </Modal>
            <SearchBox style={{ margin: '20px 0 20px 0' }}>
                <TitleStyle2>Pesquisa de Turmas</TitleStyle2>
                <Wrap>
                <WrapInput style={{ width: '15%', paddingRight: '5px' }}> <LabelInput> Estudo </LabelInput>
                  <MySelect
                    styles={multSelect}
                    placeholder={'Filtrar por Estudo'}
                    value={estudoConsulta}
                    isClearable={true}
                    isSearchable={true}
                    onChange={handleEstudoConsulta}
                    options={estudoOptions.map((item) => { return { value: item.id, label: item.nome }; })}
                  />
                </WrapInput>
                <WrapInput style={{ width: '10%', paddingRight: '5px' }}> <LabelInput> Código</LabelInput>
                    <Input
                    placeholder="Filtrar por Código"
                    style={inputStyle}
                    maxLength="50"
                    type="text"
                    name="codigo"
                    onChange={e => setCodigoConsulta(e.target.value.toUpperCase())}
                    value={codigoConsulta}
                    />
                </WrapInput>
                <WrapInput style={{ width: '15%', paddingRight: '1px' }}> <LabelInput> Tipo </LabelInput>
                  <MySelect
                    styles={multSelect}
                    placeholder={'Filtrar por Tipo...'}
                    value={tipoConsulta}
                    isClearable={true}
                    isSearchable={true}
                    onChange={handleTipoConsulta}
                    options={tipoOptions}
                  />
                </WrapInput>
                <WrapInput style={{ width: '5%', paddingRight: '5px' }}> <LabelInput> Ano </LabelInput>
                <InputMask
                    mask="9999"
                    maskChar=""
                    placeholder="Filtrar por Ano"
                    style={inputStyle}
                    type="text"
                    name="anoConsulta"
                    onChange={e => setAnoConsulta(e.target.value)}
                    value={anoConsulta}
                  />
                </WrapInput>
                <WrapInput style={{ width:'30%', marginTop: '10px' }}>
                    <ItemsSearch>
                    <IconeSearch size={25} title="Pesquisar" onClick={() => fetchSearch(true)} />
                    <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' onClick={() => openModal('insert')}>&nbsp;Novo<AddIcon></AddIcon></Button>
                    <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
                    </ItemsSearch>
                </WrapInput>
                </Wrap>
            </SearchBox>
            <WrapList>
                <HeaderList>
                <span style={{ marginLeft: '20px' }}>TURMAS  </span>
                </HeaderList>
                {
                <BodyList>
                    <TableContainer className={classes.containerModal}>
                        <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">ESTUDO</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">CÓDIGO</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">TIPO</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">LOCAL</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">INÍCIO</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">AÇÕES</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody style={{ width: '100%' }}>
                            {
                                turmas && turmas.map((row, index) => {
                                return (
                                    <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.nomeEstudo}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.codigo}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.tipo}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.local}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.dataInicio.split("-")[2] + "/" + row.dataInicio.split("-")[1] +"/"+ row.dataInicio.split("-")[0]}</TableCell>
                                        <TableCell
                                            style={{ color: textTableColor, border: 'none', display: 'flex', justifyContent: 'center' }}
                                            align="center">
                                            <AiOutlineEdit
                                            onClick={() => openModal('update',row)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="Editar"
                                            />          
                                            <AiOutlineDelete
                                            onClick={() => deleteTurma(row.id)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="Excluir"
                                            />
                                            <AiOutlineTeam
                                            onClick={() => openModalAlunosTurma(row)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="Alunos"
                                            />                
                                        </TableCell>
                                    </TableRow>
                                    )
                                    })
                                }
                                </TableBody>
                        </Table>
                    </TableContainer >
 
                </BodyList>  
                }
            </WrapList>
            <Modal open={loadDialog}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {modalLoad}
            </Modal>
        </Container>
    )
}