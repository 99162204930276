import styled from 'styled-components'

export const Container = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
background-color: ${props => props.theme.colors.background};
overflow: auto;
`;

export const TitleStyle2 = styled.h2`    
    display: flex;
    margin: 0;
    font-size: 14px;
    line-height: 25px;
    padding: 0 15px;
    border-top-left-radius:10px;
    height:25px;
    border-top-right-radius:10px;
    font-weight:bold;
    text-transform:uppercase;
    background-color: ${props => props.theme.bgBorderButtonMenu};
    color: ${props => props.theme.textHeading} !important;`

export const ModalBox2 = styled.div `
    flex-flow: column nowrap;
    align-items: center;
    justify-content: stretch;
    padding: 5px;
    border: 0px;
    margin:0;
    min-height: 350px;
    @media (max-width: 800px){
        max-height: 80vh;
    } 
`
export const Items = styled.div`
    display: flex;
    justify-content:center;
    align-items: flex-end;
    width: 100%;
    height: 48px;
    @media (max-width: 800px){
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
`;

export const modalStyle = {
    backgroundColor: 'white',
    position: 'absolute',
    width: '100%',
    maxWidth: '50vw',
    left: '50%',
    top: '50%',
    borderRadius: '10px',
    padding: '0',
    transform: `translate(-50%, -50%)`
}

export const ModalBox = styled.div`
/* background-color: ${props => props.theme.colors.surface};
display:flex */
    height: 50vh;
    display:flex;
    flex-direction:column;
    align-items: center;
        justify-content: center;
    @media (max-width: 800px){
        width: 100%;
    }
`