import styled from 'styled-components'
import { Link } from 'react-router-dom'


export const iconsCSS = {

    color: `${props => props.borderButtonMenu} !important`,
};

export const Container = styled.div `
width: 100%;
height: 100vh;
margin: 0;
padding: 0%;
background-color: #043D5D;
display: flex;
justify-content: center;
align-items: flex-start;
background-image: url(${props => props.bg});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
`;

export const Title = styled.div `
color: #ddd;
font-weight: bold;
font-size: 25px
`;


export const Form = styled.div `
width: 100%;
max-width: 800px;
height: 500px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
position: fixed;
top: 4%;
left: 50;
right: 50;
bottom: 50%;
border-radius: 10px;
transform: ${props => props.showForm};
transition: transform 0.5s ease-out;
padding-bottom: 10px;
`;

export const FormInputs = styled.form `
width: 90%;
height: 50%;
display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: column;
`;

export const TitleStyle2 = styled.h2`    
    display: flex;
    margin: 0;
    font-size: 14px;
    line-height: 25px;
    padding: 0 15px;
    border-top-left-radius:10px;
    height:25px;
    border-top-right-radius:10px;
    font-weight:bold;
    text-transform:uppercase;
    background-color: ${props => props.theme.bgBorderButtonMenu};
    color: ${props => props.theme.textHeading} !important;`

export const ModalBox2 = styled.div `
    flex-flow: column nowrap;
    align-items: center;
    justify-content: stretch;
    padding: 5px;
    border: 0px;
    margin:0;
    min-height: 350px;
    @media (max-width: 800px){
        max-height: 80vh;
    } 
`
export const Items = styled.div`
    display: flex;
    justify-content:center;
    align-items: flex-end;
    width: 100%;
    height: 48px;
    @media (max-width: 800px){
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
`;

export const modalStyle = {
    backgroundColor: 'white',
    position: 'absolute',
    width: '100%',
    maxWidth: '50vw',
    left: '50%',
    top: '50%',
    borderRadius: '10px',
    padding: '0',
    transform: `translate(-50%, -50%)`
}

export const ModalBox = styled.div`
/* background-color: ${props => props.theme.colors.surface};
display:flex */
    height: 50vh;
    display:flex;
    flex-direction:column;
    align-items: center;
        justify-content: center;
    @media (max-width: 800px){
        width: 100%;
    }
`