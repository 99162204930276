import React, { useState,useContext, useEffect } from 'react'
import { Container, TitleStyle2, ModalBox2, Items } from './styles'
import { Wrap, WrapInput, WrapList } from '../../components/Wrap'
import { Input, LabelInput, inputStyle, multSelect } from '../../components/Input';
import { SearchBox, IconeSearch, ItemsSearch } from "../../components/Search";
import { Button, CancelarBtn } from "../../components/Button";
import { MySelect, MySelect as MySelect2 } from "../../components/InputSelect";
import { api } from '../../services/axios'
import { HeaderList, BodyList } from '../../components/TabelaCadastro';
import { TableCadastroPessoa } from '../../components/TableCadastroPessoa';
import { Modal, CircularProgress } from "@material-ui/core";
import { SUCCESSMESSAGE, INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from '../../utils/types-messages'
import { useSnackbar } from 'notistack';
import { useShowModal } from '../../contexts/SharedContext'
import { modalClose, modalStyle, ModalBox, StyledBox } from '../../components/Modal'
import CancelIcon from '@material-ui/icons/Cancel';
import { ThemeContext } from 'styled-components'
import MessageAlert from '../../components/MessageAlert'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale'
import InputMask from 'react-input-mask';
import Header from "../../components/Header"

export default function CadastroPessoas(props){

  const [pessoas, setPessoas] = useState([]);
  const [nomePesquisa, setNomePesquisa] = useState('');
  const [nomeIncluir, setNomeIncluir] = useState('');
  const [apelidoIncluir, setApelidoIncluir] = useState('');
  const [cpfPesquisa, setCpfPesquisa] = useState('');
  const [cpfIncluir, setCpfIncluir] = useState('');
  const [statusParaFiltro, setStatusParaFiltro] = useState(true);
  const [tipoParaFiltro, setTipoParaFiltro] = useState(true);
  const [loadDialog, setLoadDialog] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setShowModalPessoa, showModalPessoa } = useShowModal();
  const { colors, textTableColor } = useContext(ThemeContext);
  const [idToModal, setIdToModal] = useState();
  const [sexoIncluir, setSexoIncluir] = useState(null);
  const [rgIncluir, setRgIncluir] = useState('')
  const [dataNascIncluir, setDataNascIncluir] = useState('');
  const [emailIncluir, setEmailIncluir] = useState('');
  const [estadoCivilIncluir, setEstadoCivilIncluir] = useState('');
  const [dataAdmissaoIncluir, setDataAdmissaoIncluir] = useState('');
  const [matriculaIncluir, setMatriculaIncluir] = useState('');
  const [escolaridadeIncluir, setEscolaridadeIncluir] = useState('');
  const [statusIncluir, setStatusIncluir] = useState('');
  const [pneIncluir, setPneIncluir] = useState('');
  const [nacionalidadeIncluir, setNacionalidadeIncluir] = useState('');
  const [naturalidadeIncluir, setNaturalidadeIncluir] = useState('');
  const [profissaoIncluir, setProfissaoIncluir] = useState('');
  const [tipoIncluir, setTipoIncluir] = useState(null);

  const handleSexoIncluir = (item) => {
    setSexoIncluir(item);
  }

  const handleTipoIncluir = (item) => {
    setTipoIncluir(item);
  }

  const handleEstadoCivilIncluir = (item) => {
    setEstadoCivilIncluir(item);
  }

  const handleEscolaridadeIncluir = (item) => {
    setEscolaridadeIncluir(item);
  }

  const handleStatusIncluir = (item) => {
    setStatusIncluir(item);
  }

  const sexoOptions = [
    { value: "F", label: "Feminino" },
    { value: "M", label: "Masculino" }
  ]
  const statusOptions = [
    { value: "A", label: "Ativo" },
    { value: "I", label: "Inativo" }
  ]
  const estadoCivilOptions = [
    { value: "Solteiro (a)", label: "Solteiro (a)" },
    { value: "Casado (a)", label: "Casado (a)" },
    { value: "Separado (a)", label: "Separado (a)" },
    { value: "Divorciado (a)", label: "Divorciado (a)" },
    { value: "Viúvo (a)", label: "Viúvo (a)" },
    { value: "União Estável", label: "União Estável" },
  ]

  const escolaridadeOptions = [
    { value: "Educação Infantil", label: "Educação Infantil" },
    { value: "Fundamental - Completo", label: "Fundamental - Completo" },
    { value: "Fundamental - Incompleto", label: "Fundamental - Incompleto" },
    { value: "Médio - Completo", label: "Médio - Completo" },
    { value: "Médio - Incompleto", label: "Médio - Incompleto" },
    { value: "Superior - Completo", label: "Superior - Completo" },
    { value: "Superior - Incompleto", label: "Superior - Incompleto" },
    { value: "Pós Graduação - Lato Sensu", label: "Pós Graduação - Lato Sensu" },
    { value: "Mestrado", label: "Mestrado" },
    { value: "Doutorado", label: "Doutorado" },
  ]

  const tipoOptions = [
    { value: "Amigo", label: "Amigo" },
    { value: "Aprendiz", label: "Aprendiz" },
    { value: "Membro Iniciante", label: "Membro Iniciante" },
    { value: "Membro Efetivo", label: "Membro Efetivo" }
  ]

  const handleStateFilter = (item) => {
      setStatusParaFiltro(item);
  };

  const handleTypeFilter = (item) => {
    setTipoParaFiltro(item);
};

  async function fetchSearch(showSnack=true) {

    if (showSnack) {const key = enqueueSnackbar('Pesquisando Pessoas ...', INFOMESSAGE);};
    try {
      let params = ''
      if (nomePesquisa !== '') params += `&nome=${nomePesquisa}`
      if (cpfPesquisa !== '') params += `&cpf=${cpfPesquisa}`

      if ((tipoParaFiltro.value !== '') && (tipoParaFiltro.value !== undefined)){
        params += `&tipo=${tipoParaFiltro.value}`
      }

      if ((statusParaFiltro.value !== '') && (statusParaFiltro.value !== undefined)){
        params += `&status=${statusParaFiltro.value}`
      }
      setLoadDialog(true);

      const { data } = await api.get(`pessoa?` + params);
      setPessoas(data);
      setLoadDialog(false);
    } catch (error) {
      enqueueSnackbar("Erro ao buscar Pessoas", ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  function clearSearch() {
      setStatusParaFiltro({ value: '', label: 'Selecione...' });
      setTipoParaFiltro({ value: '', label: 'Selecione...' });
      setNomePesquisa('');
      setCpfPesquisa('');
      setPessoas([]);
  }

  const handleClose = () => {
    setShowModalPessoa({ show: false });
  };

  const handleCloseCreate = () => {
    setShowModalPessoa({ show: false });
  }

  async function sendPessoa() {

    const key = enqueueSnackbar('Cadastrando Pessoa ...', INFOMESSAGE);
    
    try {

      const valoresInseridos = {
        nome: nomeIncluir,
        apelido: apelidoIncluir,
        dataNascimento: dataNascIncluir,
        sexo: sexoIncluir.value,
        cpf: cpfIncluir,
        rg: rgIncluir,
        nacionalidade:nacionalidadeIncluir,
        naturalidade:naturalidadeIncluir,
        email: emailIncluir,
        estadoCivil: estadoCivilIncluir.value,
        escolaridade: escolaridadeIncluir.value,
        dataAdmissao: dataAdmissaoIncluir,
        matricula: matriculaIncluir,
        profissao: profissaoIncluir,
        pne: pneIncluir,
        status: statusIncluir.value,
        tipo:tipoIncluir.value
      };

      setLoadDialog(true);
      const { data } = await api.post("/pessoa/", valoresInseridos);
      if (data.message!=null) throw {message: data.message}
      enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
      setShowModalPessoa({ show: false });
      clearInclude();
      fetchSearch(false);
      setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
  };

  async function editPessoa(id) {

    const key = enqueueSnackbar('Alterando Pessoa ...', INFOMESSAGE);

    try {

      const valoresEditados = {
        nome: nomeIncluir,
        apelido: apelidoIncluir,
        dataNascimento: dataNascIncluir,
        sexo: sexoIncluir.value,
        cpf: cpfIncluir,
        rg: rgIncluir,
        nacionalidade:nacionalidadeIncluir,
        naturalidade:naturalidadeIncluir,
        email: emailIncluir,
        estadoCivil: estadoCivilIncluir.value,
        escolaridade: escolaridadeIncluir.value,
        dataAdmissao: dataAdmissaoIncluir,
        matricula: matriculaIncluir,
        profissao: profissaoIncluir,
        pne: pneIncluir,
        status: statusIncluir.value,
        tipo:tipoIncluir.value
      };

      setLoadDialog(true);
      const { data } = await api.put("/pessoa/" + id, valoresEditados);
      if (data.message!=null) throw {message: data.message}
      enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
      setShowModalPessoa({ show: false });
      clearInclude();
      fetchSearch(false);
      setLoadDialog(false);

    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
  };

  function clearInclude(){
    setNomeIncluir(null);
    setApelidoIncluir(null);
    setCpfIncluir(null);
    setRgIncluir(null);
    setDataNascIncluir(null);
    setEmailIncluir(null);
    setEstadoCivilIncluir([]);
    setDataAdmissaoIncluir(null);
    setMatriculaIncluir(null);
    setEscolaridadeIncluir([]);
    setPneIncluir(null);
    setNacionalidadeIncluir(null);
    setNaturalidadeIncluir(null);
    setSexoIncluir([]);
    setProfissaoIncluir(null);
    setTipoIncluir([]);

  }

  useEffect(() => {
    if (showModalPessoa.action === 'editar') {
      setNomeIncluir(showModalPessoa.item.nome);
      setApelidoIncluir(showModalPessoa.item.apelido);
      setCpfIncluir(showModalPessoa.item.cpf);
      setRgIncluir(showModalPessoa.item.rg);
      setDataNascIncluir(showModalPessoa.item.dataNascimento + 'T00:00:00');
      setEmailIncluir(showModalPessoa.item.email);
      setEstadoCivilIncluir({value:`${showModalPessoa.item.estadoCivil}`, label:`${showModalPessoa.item.estadoCivil}`});
      setDataAdmissaoIncluir(showModalPessoa.item.dataAdmissao + 'T00:00:00');
      setMatriculaIncluir(showModalPessoa.item.matricula);
      setEscolaridadeIncluir({value:`${showModalPessoa.item.escolaridade}`, label:`${showModalPessoa.item.escolaridade}`});
      setPneIncluir(showModalPessoa.item.pne);
      setNacionalidadeIncluir(showModalPessoa.item.nacionalidade);
      setNaturalidadeIncluir(showModalPessoa.item.naturalidade);
      setIdToModal(showModalPessoa.item.id);
      setProfissaoIncluir(showModalPessoa.item.profissao);
      setTipoIncluir({value:showModalPessoa.item.tipo,label:showModalPessoa.item.tipo})

      if (showModalPessoa.item.sexo === "M") {
        setSexoIncluir({ value: "M", label: "Masculino" });
      } else {
        setSexoIncluir({ value: "F", label: "Feminino" });
      }

      if (showModalPessoa.item.status === "A") {
        setStatusIncluir({ value: "A", label: "Ativo" });
      } else {
        setStatusIncluir({ value: "I", label: "Inativo" });
      }
    } 
  }, [showModalPessoa.action])

  function openModal(action) {
    clearInclude();
    setShowModalPessoa({ show: true, title: 'INCLUIR PESSOA', action: 'inserir' });
  }

  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput className="alertText" style={{ alignItems: 'center' }}>
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
      </WrapInput>
    </div>
  );

  const body = (
    <StyledBox className={'disableFocus alert-modal'}>
      <TitleStyle2>
        {showModalPessoa.title}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose()}
        />
      </TitleStyle2>
      <ModalBox2>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput style={{ position: 'relative', width: '65%', marginLeft: '0px' }}>
            <LabelInput>Nome <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <Input
              placeholder="Inserir Nome"
              style={inputStyle}
              maxLength="300"
              type="text"
              name="nome"
              onChange={e => setNomeIncluir(e.target.value)}
              value={nomeIncluir}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '25%', marginLeft: '0px' }}>
            <LabelInput>Apelido</LabelInput>
            <Input
              placeholder="Inserir Apelido"
              style={inputStyle}
              maxLength="50"
              type="text"
              name="apelido"
              onChange={e => setApelidoIncluir(e.target.value)}
              value={apelidoIncluir}
            />
          </WrapInput>
        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>          
          <WrapInput style={{ position: 'relative', width: '30%' }}>
            <LabelInput>Sexo <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect2
              styles={multSelect}
              placeholder={'Opções...'}
              value={sexoIncluir}
              isClearable={true}
              isSearchable={true}
              onChange={handleSexoIncluir}
              options={sexoOptions}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%', marginLeft: '0px' }}>
            <LabelInput>CPF</LabelInput>
            <InputMask
              mask="999.999.999-99"
              placeholder="Inserir CPF"
              style={inputStyle}
              type="text"
              name="cpf"
              onChange={e => setCpfIncluir(e.target.value)}
              value={cpfIncluir}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%', marginLeft: '0px' }}>
            <LabelInput>RG</LabelInput>
            <Input
              placeholder="Inserir RG"
              style={inputStyle}
              maxLength="15"
              type="text"
              name="rg"
              onChange={e => setRgIncluir(e.target.value)}
              value={rgIncluir}
            />
          </WrapInput>
        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput style={{ position: 'relative', width: '30%' }}>
            <LabelInput>Tipo <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect2
              styles={multSelect}
              placeholder={'Opções...'}
              value={tipoIncluir}
              isClearable={true}
              isSearchable={true}
              onChange={handleTipoIncluir}
              options={tipoOptions}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%', marginLeft: '0px' }}>
            <LabelInput>Nacionalidade</LabelInput>
            <Input
              placeholder="Inserir Nacionalidade"
              style={inputStyle}
              maxLength="50"
              type="text"
              name="nacionalidade"
              onChange={e => setNacionalidadeIncluir(e.target.value)}
              value={nacionalidadeIncluir}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%' }}>
            <LabelInput>Naturalidade (Cidade-UF)</LabelInput>
            <Input
              placeholder="Inserir Naturalidade"
              style={inputStyle}
              maxLength="100"
              type="text"
              name="naturalidade"
              onChange={e => setNaturalidadeIncluir(e.target.value)}
              value={naturalidadeIncluir}
            />
          </WrapInput>
        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput style={{ position: 'relative', width: '30%', marginLeft: '0px' }}>
            <LabelInput style={{padding: '0.7px'}}>Data Nascimento</LabelInput>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <KeyboardDatePicker 
                value={dataNascIncluir} 
                onChange={setDataNascIncluir}
                format="dd/MM/yyyy"
                clearLabel="Limpar"
                cancelLabel="Cancelar"
                clearable
                minDateMessage="Não é possível buscar por datas anteiores a 01/01/1900."
                invalidDateMessage="A data inserida não é válida!"
                helperText={""}
                id="dataNasc"
                maxDate={new Date(new Date())}
                margin="dense"
                inputVariant="outlined"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%' }}>
            <LabelInput>E-mail</LabelInput>
            <Input
              placeholder="Inserir E-mail"
              style={inputStyle}
              maxLength="50"
              type="text"
              name="email"
              onChange={e => setEmailIncluir(e.target.value)}
              value={emailIncluir}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%' }}>
            <LabelInput>Estado Civil </LabelInput>
            <MySelect2
              styles={multSelect}
              placeholder={'Opções...'}
              value={estadoCivilIncluir}
              isClearable={true}
              isSearchable={true}
              onChange={handleEstadoCivilIncluir}
              options={estadoCivilOptions}
            />
          </WrapInput>
        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput style={{ position: 'relative', width: '30%', marginLeft: '0px' }}>
            <LabelInput style={{padding: '0.7px'}}>Data Admissão</LabelInput>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <KeyboardDatePicker 
                value={dataAdmissaoIncluir} 
                onChange={setDataAdmissaoIncluir}
                format="dd/MM/yyyy"
                clearLabel="Limpar"
                cancelLabel="Cancelar"
                clearable
                minDateMessage="Não é possível buscar por datas anteiores a 01/01/1900."
                invalidDateMessage="A data inserida não é válida!"
                helperText={""}
                id="dataAdmissao"
                maxDate={new Date(new Date())}
                margin="dense"
                inputVariant="outlined"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%' }}>
            <LabelInput>Matrícula</LabelInput>
            <Input
              placeholder="Inserir Matrícula"
              style={inputStyle}
              maxLength="50"
              type="text"
              name="matricula"
              onChange={e => setMatriculaIncluir(e.target.value)}
              value={matriculaIncluir}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%' }}>
            <LabelInput>Escolaridade</LabelInput>
            <MySelect2
              styles={multSelect}
              placeholder={'Opções...'}
              value={escolaridadeIncluir}
              isClearable={true}
              isSearchable={true}
              onChange={handleEscolaridadeIncluir}
              options={escolaridadeOptions}
            />
          </WrapInput>
        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput style={{ position: 'relative', width: '45%' }}>
            <LabelInput>Profissão</LabelInput>
            <Input
              placeholder="Profissão"
              style={inputStyle}
              maxLength="150"
              type="text"
              name="profissao"
              onChange={e => setProfissaoIncluir(e.target.value)}
              value={profissaoIncluir}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%' }}>
            <LabelInput>Necessidade Especial</LabelInput>
            <Input
              placeholder="Inserir Necessidade"
              style={inputStyle}
              maxLength="50"
              type="text"
              name="pne"
              onChange={e => setPneIncluir(e.target.value)}
              value={pneIncluir}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '20%' }}>
            <LabelInput>Status <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect2
              styles={multSelect}
              placeholder={'Opções...'}
              value={statusIncluir}
              isClearable={true}
              isSearchable={true}
              onChange={handleStatusIncluir}
              options={statusOptions}
            />
          </WrapInput>
        </Wrap>
        <Wrap>
          <WrapInput className="wrapInputModal">
            <Items>
              <CancelarBtn type="button" onClick={handleCloseCreate}> Sair </CancelarBtn>
              <Button type="button" onClick={() => showModalPessoa.action === 'inserir' ? sendPessoa() : editPessoa(idToModal)} > Salvar </Button>
            </Items>
          </WrapInput>
        </Wrap>
      </ModalBox2>
    </StyledBox>
  );

  return (
        <Container>
          <Header/>
          <Modal open={showModalPessoa.show}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
        <SearchBox style={{ margin: '20px 0 20px 0' }}>
          <TitleStyle2>Pesquisa de Pessoas</TitleStyle2>
          <Wrap>
            <WrapInput style={{ width: '10%', paddingRight: '5px' }}> <LabelInput> Nome</LabelInput>
              <Input
                //placeholder="Pesquisar Nome Completo"
                style={inputStyle}
                maxLength="300"
                type="text"
                name="nomePesquisa"
                onChange={e => setNomePesquisa(e.target.value)}
                value={nomePesquisa}
              />
            </WrapInput>
            <WrapInput style={{ width: '5%', paddingRight: '10px' }}>
              <LabelInput> CPF </LabelInput>
              <InputMask
                mask="999.999.999-99"
                style={inputStyle}
                type="text"
                name="cpfPesquisa"
                onChange={e => setCpfPesquisa(e.target.value)}
                value={cpfPesquisa}
              />
            </WrapInput>
            <WrapInput style={{ width: '10%', paddingRight: '0px' }}>
              <LabelInput> Tipo </LabelInput>
              <MySelect
                styles={multSelect}
                placeholder="Selecione..."
                onChange={handleTypeFilter}
                options={tipoOptions}
                value={tipoParaFiltro}
              />
            </WrapInput>
            <WrapInput style={{ width: '5%', paddingRight: '0px' }}>
              <LabelInput> Status </LabelInput>
              <MySelect
                styles={multSelect}
                placeholder="Selecione..."
                onChange={handleStateFilter}
                options={[{ value: 'A', label: 'Ativo' }, { value: 'I', label: 'Inativo' }]}
                value={statusParaFiltro}
              />
            </WrapInput>
            <WrapInput style={{ marginTop: '10px' }}>
              <ItemsSearch>
                <IconeSearch size={25} title="Pesquisar" onClick={() => fetchSearch(true)} />
                <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' onClick={() => openModal('save')}>&nbsp;Novo<AddIcon></AddIcon></Button>
                <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
              </ItemsSearch>
            </WrapInput>
          </Wrap>
        </SearchBox>
        <WrapList>
          <HeaderList>
            <span style={{ marginLeft: '20px' }}>PESSOAS  </span>
          </HeaderList>
          {
            <BodyList>
              <TableCadastroPessoa pessoas={pessoas} />
            </BodyList>  
          }
        </WrapList>
        <Modal open={loadDialog}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {modalLoad}
        </Modal>
      </Container>
    )
}