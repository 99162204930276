import React, { useEffect} from 'react'
import { Logout } from '../../services/helpers'
import { Container } from '../Login/styles';


export default function Sair(props) {
    useEffect(() => {
        Logout();
      }, []);

    return(
        <Container/>
    );
}
