import axios from 'axios'

export const keySession = "63c4a13036f8eb6ff9dfec07e765927d-thelos-user-session"
export const keyUserAccess = "63c4a16036f8eb6ff9dfec07e733337d-thelos-user-access"


export const api = axios.create({
  baseURL: process.env.REACT_APP_BASEURL
});

export const apiNoAuth = axios.create({
  baseURL: process.env.REACT_APP_BASEURL
});

api.interceptors.request.use(async config => {
 
  try {
    
    const temp = await localStorage.getItem(keySession)
    const header = JSON.parse(temp)
    
    if (header.token && header.authuser) {
      config.headers.Authorization = `Bearer ${header.token}`;
      config.headers.authuser = header.authuser;
    }

  } catch (error) {
    //localStorage.removeItem(keySession)
    //window.location = "/login"
    console.log(error)
  }
  return config;
});

api.interceptors.response.use(response => {
  return response
}, error => {
  if (error.hasOwnProperty('response')) {    
    if (error.response.status === 401) {
      error.message = error.response.data.message;
      localStorage.removeItem(keySession)
      window.location = "/login"
    }
    else{
      return error.response
    }
  }
})
