import React, { useState, useContext, useEffect } from 'react';
import { Wrap, WrapInput, WrapList } from '../../components/Wrap';
import { Input, LabelInput, inputStyle,multSelect } from '../../components/Input';
import { SearchBox, IconeSearch, ItemsSearch } from "../../components/Search";
import { Button, CancelarBtn } from "../../components/Button";
import { Container, TitleStyle2,Items} from './styles'
import { HeaderList, BodyList } from '../../components/TabelaCadastro';
import { Modal, CircularProgress } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '../../components/SharedComponents/table';
import { shade } from 'polished'
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { ThemeContext } from 'styled-components';
import { ERRORMESSAGE, SUCCESSMESSAGE, INFOMESSAGE } from '../../utils/types-messages';
import { useSnackbar } from 'notistack';
import { api } from '../../services/axios';
import { makeStyles } from '@material-ui/core/styles';
import { modalClose, ModalBox, StyledBox } from '../../components/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import MessageAlert from '../../components/MessageAlert';
import { useShowModal } from '../../contexts/SharedContext'
import { MySelect } from "../../components/InputSelect";
import Header from "../../components/Header"

export default function CadastroFuncionalidades(props){
    const { enqueueSnackbar } = useSnackbar();
    const { colors, textTableColor } = useContext(ThemeContext);
    const [funcionalidades, setFuncionalidades] = useState([]);
    const [nomeConsulta, setNomeConsulta] = useState('');
    const [statusConsulta, setStatusConsulta] = useState('');
    const [loadDialog, setLoadDialog] = useState(false);
    const [idToModal, setIdToModal] = useState();
    const { setShowModalGeral, showModalGeral } = useShowModal();
    const [nome, setNome] = useState(null);
    const [rota, setRota] = useState(null);
    const [status, setStatus] = useState(null);

    const useStyles = makeStyles({
        wrapBtnModal: {
          alignItems: 'flex-start'
        },
        containerModal: {
          boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
          borderRadius: '0'
        },
        headColModal: {
          backgroundColor: colors.surface,
          textTransform: 'uppercase',
          borderRadius: '0',
          fontSize: '14px'
        }
    });
    const classes = useStyles();

    const statusOptions = [
      { value: "A", label: "Ativo" },
      { value: "I", label: "Inativo" }
    ]

    const handleStatus = (item) => {
      setStatus(item);
    }

    const handleStatusConsulta = (item) => {
      setStatusConsulta(item);
    }

    async function fetchSearch(showSnack=true) {
        if (showSnack) {const key = enqueueSnackbar('Pesquisando Funcionalidades ...', INFOMESSAGE);};
        try {
            let params = ''
            if (nomeConsulta !== '') params += `&nome=${nomeConsulta}`
            if ((statusConsulta !== null) && (statusConsulta.value !== undefined) && (statusConsulta.value !== '')){
              params += `&status=${statusConsulta.value}`
            }
            setLoadDialog(true);
            const { data } = await api.get(`funcionalidade?` + params);
            setFuncionalidades(data);
            setLoadDialog(false);
        } catch (error) {
            enqueueSnackbar("Erro ao buscar Funcionalidades", ERRORMESSAGE);
            setLoadDialog(false);
        }
    }

    function clearSearch() {
        setFuncionalidades([]);
        setNomeConsulta('');
        setStatusConsulta([]);
    }

    function openModal(action, item) {
        clearInclude();
        if (action === "insert") {
            setShowModalGeral({ show: true, title: 'INCLUIR FUNCIONALIDADE', action: 'inserir' });
        }
        else {
            setShowModalGeral({ show: true, title: 'ALTERAR FUNCIONALIDADE', action: 'editar', item: item });
        }
            
    }

    function clearInclude() {
        setNome(null);
        setRota(null);
        setStatus([]);
        
    }

    const handleClose = () => {
        setShowModalGeral({ show: false });
      };

    async function sendFuncionalidade() {

        const key = enqueueSnackbar('Cadastrando Funcionalidade ...', INFOMESSAGE);
        
        try {
    
          const valoresInseridos = {
            nome: nome,
            rota: rota,
            status: status.value
          };
    
          setLoadDialog(true);
          const { data } = await api.post("/funcionalidade/", valoresInseridos);
          if (data.message!=null) throw {message: data.message}
          enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
          setShowModalGeral({ show: false });
          clearInclude();
          fetchSearch(false);
          setLoadDialog(false);
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
          setLoadDialog(false);
        }
      };
    
      async function editFuncionalidade(id) {
    
        const key = enqueueSnackbar('Alterando Funcionalidade ...', INFOMESSAGE);
    
        try {
    
          const valoresEditados = {
            nome: nome,
            rota: rota,
            status: status.value
          };
    
          setLoadDialog(true);
          const { data } = await api.put("/funcionalidade/" + id, valoresEditados);
          if (data.message!=null) throw {message: data.message}
          enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
          setShowModalGeral({ show: false });
          clearInclude();
          fetchSearch(false);
          setLoadDialog(false);
    
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
          setLoadDialog(false);
        }
      };

      useEffect(() => {
        if (showModalGeral.action === 'editar') {
            setIdToModal(showModalGeral.item.id);
            setNome(showModalGeral.item.nome);
            setRota(showModalGeral.item.rota);
            if (showModalGeral.item.status === "A") {
              setStatus({ value: "A", label: "Ativo" });
            } else {
              setStatus({ value: "I", label: "Inativo" });
            }
        }
      }, [showModalGeral.action])


    async function deleteFuncionalidade(id) {
        try {
          var resposta = window.confirm("Deseja remover esse registro?");
          if (resposta) {
            const key = enqueueSnackbar('Excluindo Funcionalidade ...', INFOMESSAGE);
            const { data } = await api.delete("/funcionalidade/" + id);
            if ((data.success !== undefined) && (!data.success))
            {
              throw new Error(data.message)
            }
            enqueueSnackbar(<MessageAlert message={"Funcionalidade excluída com sucesso!"} />, SUCCESSMESSAGE);
            fetchSearch(false);
          }
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
        }
    };
    

    const bodyFuncionalidade = (
        <StyledBox className={'disableFocus alert-modal'}>
          <TitleStyle2>
            {showModalGeral.title}
            <CancelIcon
              style={modalClose}
              color={colors.error}
              size="20"
              title="Fechar"
              onClick={() => handleClose()}
            />
          </TitleStyle2>
          <ModalBox>
            <Wrap>
              <WrapInput style={{ position: 'relative', width: '30%'}}>
                <LabelInput>Nome <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <Input
                  placeholder="Inserir Nome"
                  style={inputStyle}
                  maxLength="100"
                  type="text"
                  name="nome"
                  onChange={e => setNome(e.target.value)}
                  value={nome}
                />
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput style={{ position: 'relative', width: '30%'}}>
                <LabelInput>Rota <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <Input
                  placeholder="Inserir Rota"
                  style={inputStyle}
                  maxLength="255"
                  type="text"
                  name="rota"
                  onChange={e => setRota(e.target.value)}
                  value={rota}
                />
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput style={{ position: 'relative', width: '30%'}}>
                <LabelInput>Status <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <MySelect
                  styles={multSelect}
                  placeholder={'Selecione o Status'}
                  value={status}
                  isClearable={true}
                  isSearchable={true}
                  onChange={handleStatus}
                  options={statusOptions}
                />
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput className="wrapInputModal">
                <Items>
                  <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
                  <Button type="button" onClick={() => showModalGeral.action === 'inserir' ? sendFuncionalidade() : editFuncionalidade(idToModal)} > Salvar </Button>
                </Items>
              </WrapInput>
            </Wrap>
          </ModalBox>
        </StyledBox>
      );
    

    const modalLoad = (
        <div className={'disabeFocus alert-modal'}>
            <WrapInput className="alertText" style={{ alignItems: 'center' }}>
            <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
            </WrapInput>
        </div>
    );

    return (
        <Container>
          <Header/>
            <Modal open={showModalGeral.show}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
            {bodyFuncionalidade}
            </Modal>
            <SearchBox style={{ margin: '20px 0 20px 0' }}>
                <TitleStyle2>Pesquisa de Funcionalidades</TitleStyle2>
                <Wrap>
                <WrapInput style={{ width: '20%', paddingRight: '5px' }}> <LabelInput> Nome </LabelInput>
                    <Input
                        placeholder="Filtrar por Nome"
                        style={inputStyle}
                        maxLength="100"
                        type="text"
                        name="nome"
                        onChange={e => setNomeConsulta(e.target.value)}
                        value={nomeConsulta}
                    />
                </WrapInput>
                <WrapInput style={{ width: '10%', paddingRight: '1px' }}> <LabelInput> Status </LabelInput>
                  <MySelect
                    styles={multSelect}
                    placeholder={'Filtrar por Status...'}
                    value={statusConsulta}
                    isClearable={true}
                    isSearchable={true}
                    onChange={handleStatusConsulta}
                    options={statusOptions}
                  />
                </WrapInput>
                <WrapInput style={{ width:'30%', marginTop: '10px' }}>
                    <ItemsSearch>
                    <IconeSearch size={25} title="Pesquisar" onClick={() => fetchSearch(true)} />
                    <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' onClick={() => openModal('insert')}>&nbsp;Novo<AddIcon></AddIcon></Button>
                    <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
                    </ItemsSearch>
                </WrapInput>
                </Wrap>
            </SearchBox>
            <WrapList>
                <HeaderList>
                <span style={{ marginLeft: '20px' }}>FUNCIONALIDADES</span>
                </HeaderList>
                {
                <BodyList>
                    <TableContainer>
                        <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">NOME</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">ROTA</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">STATUS</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">AÇÕES</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody style={{ width: '100%' }}>
                            {
                                funcionalidades && funcionalidades.map((row, index) => {
                                return (
                                    <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.nome}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.rota}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.status}</TableCell>
                                        <TableCell
                                            style={{ color: textTableColor, border: 'none', display: 'flex', justifyContent: 'center' }}
                                            align="center">
                                            <AiOutlineEdit
                                            onClick={() => openModal('update',row)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="Editar"
                                            />          
                                            <AiOutlineDelete
                                            onClick={() => deleteFuncionalidade(row.id)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="excluir"
                                            />                
                                        </TableCell>
                                    </TableRow>
                                    )
                                    })
                                }
                                </TableBody>
                        </Table>
                    </TableContainer >
 
                </BodyList>  
                }
            </WrapList>
            <Modal open={loadDialog}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {modalLoad}
            </Modal>
        </Container>
    )
}