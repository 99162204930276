import styled from 'styled-components'
import { FaSearch, FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import Select from 'react-select';
import React from 'react';
import InputMask from 'react-input-mask';


export const Container = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
background-color: ${props => props.theme.colors.background};
overflow: auto;
`;

export const WrapTable = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
height: 300px;
`;

export const TitleStyle2 = styled.h2`    
    display: flex;
    margin: 0;
    font-size: 14px;
    line-height: 25px;
    padding: 0 15px;
    border-top-left-radius:10px;
    height:25px;
    border-top-right-radius:10px;
    font-weight:bold;
    text-transform:uppercase;
    background-color: ${props => props.theme.bgBorderButtonMenu};
    color: ${props => props.theme.textHeading} !important;`

    
export const SearchBox = styled.div`
background-color: ${props => props.theme.colors.surface};
margin:20px 15px;
padding:0;
border-radius:7px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width:93.7%;
`;

export const WrapButtons = styled.div`
color: gray;
display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: row;
width: 100%;
background-color: ${props => props.bgColor};
padding-top: 10px;
padding-bottom: 10px;
`;


export const NextPage = styled(FaAngleRight)
    `
cursor: pointer;
&:hover{
opacity: 0.7;
}
`;
export const PrevPage = styled(FaAngleLeft)
    `
cursor: pointer;
&:hover{
opacity: 0.7;
}
`;


export const WrapInput = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 20%;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 20px;
@media (max-width: 800px){
    width: 60%;
}
`;

export const Items = styled.div`
display: flex;
justify-content:center;
align-items: flex-end;
width: 100%;
height: 48px;
@media (max-width: 800px){
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
`;

export const ItemsSearch = styled.div`
display: flex;
justify-content:left;
align-items: flex-end;
width: 100%;
height: 48px;
@media (max-width: 800px){
    width: 100%;
    justify-content: left;
    align-items: left;
    margin-top: 10px;
}
`;

export const Wrap = styled.div`
display: flex;
justify-content: space-around;
align-items: flex-start;
flex-direction: row;
flex-wrap: wrap;
width: 100%;
padding-top: 20px;
`;

export const IconeSearch = styled(FaSearch)
    `
display: flex;
justify-content: flex-start;
align-items: flex-start;
&:hover{
    opacity: 0.7;
}
`;



export const WrapList = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
height: 100%;
`;

export const BodyList = styled.div`
display: flex;
justify-content: flex-start;
flex-direction: column;
align-items: center;
width: 94%;
background-color: white;
`;


export const HeaderList = styled.div`
background-color: ${props => props.theme.bgBorderButtonMenu};
color: ${props => props.theme.textHeading} !important;
display: flex;
justify-content: flex-start;
align-items: center;
font-size: 14px;
font-weight:bold;
text-transform:uppercase;
height: 25px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 94%;
`;



export const Row = styled.div`
color: gray;
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: row;
width: 100%;
background-color: ${props => props.bgColor};
padding-top: 10px;
padding-bottom: 10px;
`;

export const Column = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
width: 20%;
`;

export const inputStyle = {
    borderRadius: '5px',
    borderColor: 'gray',
    width: '94%',
    fontSize: 14,
    height: 30,
    minHeight: 30,
};

export const MySelect = styled(Select)
    `
background: ${props => props.theme.colors.surface};
border-radius: 5px;
width: 95%;
`;

export const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        fontSize: 14,
        margin: 0,
        //backgroundColor: state.isSelected ? "#eee" : "",
        textAlign: "left",
        cursor: "pointer"

    }),
    container: base => ({
        ...base,

        width: "100%"
    }),
    control: base => ({
        ...base,
        backgroundColor: 'transparent',
        borderColor: 'gray',
        height: 30,
        minHeight: 30,
        fontSize: 14,
        padding: 0,
        margin: 0,
        borderRadius: 5,
        width: "100%",
        textAlign: "left",
        cursor: "pointer"
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 0,
    }),
    indicatorSeparator: base => ({
        ...base,
        display: "none"
    }),
    valueContainer: base => ({
        ...base,
        padding: 0,
        paddingLeft: 2
    })
};

export const modalStyle = {
    backgroundColor: 'white',
    position: 'absolute',
    width: '100%',
    maxWidth: '50vw',
    left: '50%',
    top: '50%',
    borderRadius: '10px',
    padding: '0',
    transform: `translate(-50%, -50%)`
}

export const ModalBox = styled.div`
/* background-color: ${props => props.theme.colors.surface};
display:flex */
height: 50vh;
display:flex;
flex-direction:column;
align-items: center;
    justify-content: center;
@media (max-width: 800px){
    width: 100%;
}
`

export const fieldSetStyle = {
    margin: '0.5px',
    borderColor:'#aeaeae',
    borderRadius: '10px',
    paddingLeft: '0.1px'
}
// export const legendStyle = {
//     color: '#043D5D',
//     bold: {fontWeight: 'bold'}
//     //paddingLeft: '10px'
// }

export const legendStyle = {
    fontWeight: 'bold',
    color: '#043D5D',
    fontSize: '14px',
    paddingLeft :'5px'
}


export const Mask2 = (props) => (
    <InputMask style={{borderRadius:'5px',width:'100%',
    border: '1px gray solid',height: '30px'
    }} mask="(99)99999-9999" value={props.value} onChange={props.onChange} placeholder="Inserir Celular"/>
  );
  export const Mask1 = (props) => (
    <InputMask style={{borderRadius:'5px',width:'100%',
    border: '1px gray solid',height: '30px'
    }} mask="(99)9999-9999" value={props.value} onChange={props.onChange} placeholder="Inserir Telefone"/>
  );

export const ModalBox2 = styled.div `
flex-flow: column nowrap;
align-items: center;
justify-content: stretch;
padding: 5px;
border: 0px;
margin:0;
min-height: 350px;
@media (max-width: 800px){
    max-height: 80vh;
} 
`

export const multSelect = {
    option: (provided, state) => ({
        ...provided,
        fontSize: 14,
        margin: 0,
        //backgroundColor: state.isSelected ? "#eee" : "",
        textAlign: "left",
        cursor: "pointer"

    }),
    container: base => ({
        ...base,

        width: "100%"
    }),
    control: base => ({
        ...base,
        backgroundColor: 'white',
        borderColor: 'gray',
        height: 35,
        minHeight: 35,
        fontSize: 14,
        padding: 0,
        margin: 0,
        borderRadius: 5,
        width: "101%",
        textAlign: "left",
        cursor: "pointer"
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 0,
    }),
    indicatorSeparator: base => ({
        ...base,
        display: "none"
    }),
    valueContainer: base => ({
        ...base,
        padding: 0,
        paddingLeft: 2
    })
};
  