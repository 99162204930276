import React, { useState, createContext, useContext } from 'react'
import light from '../themes/light'

const ThemeContext = createContext()

export default function ThemeProvider({ children }) {
    const [theme, handleTheme] = useState(() => {
        const storageValue = localStorage.getItem('theme')
        if (storageValue) {
            return JSON.parse(storageValue)
        } else {
            return light
        }
    })
    return <ThemeContext.Provider value={{ theme, handleTheme }}>
        {children}
    </ThemeContext.Provider>
}

export function useTheme() {
    const context = useContext(ThemeContext)
    const { theme, handleTheme } = context
    return { theme, handleTheme }
}