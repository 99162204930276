import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import './menu.css'
import {GetSessionUser} from '../../services/helpers'

export default function Menu(props) {

    const [menuList, setMenuList] = useState([]);

    const GetUserSession = () => {
        const {funcionalidades} = GetSessionUser();
        setMenuList(funcionalidades);
    }

    useEffect(() => {
        GetUserSession();
    }, []);

    const menuLista = [
        { url: "/home", label: "Home" },
        { url: "/cadastropessoas", label: "Cadastro de Pessoas" },
        { url: "/cadastroestudos", label: "Cadastro de Estudos" },
        { url: "/cadastrotemaestudo", label: "Cadastro de Temas de Estudos" },
        { url: "/cadastroturmas", label: "Cadastro de Turmas" },
        { url: "/cadastroareas", label: "Cadastro de Áreas" },
        { url: "/cadastropessoaarea", label: "Cadastro de Pessoas na Área" },
        { url: "/cadastroformularios", label: "Cadastro de Formulários" },
        { url: "/cadastroperguntas", label: "Cadastro de Perguntas" },
        { url: "/cadastrofuncionalidades", label: "Cadastro de Funcionalidades" },
        { url: "/cadastroperfis", label: "Cadastro de Perfis" },
        { url: "/logout", label: "Sair" }
    ]

    return (
        <nav class="app-menu">
            <ul className="app-menu__list">
                <Link className="app-menu__link" to="/home">
                    Home
                </Link>
                {
                    menuList && menuList.map((row,index) => {
                        return(
                            <li className="app-menu__item">
                            <Link className="app-menu__link" to={row.url}>
                                {row.label}
                            </Link>
                        </li>
                        )
                    })
                }
                <Link className="app-menu__link" to="/logout">
                    Sair
                </Link>
            </ul>
        </nav>
    );

}