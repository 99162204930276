import React, { useState, useContext, useEffect } from 'react';
import { Wrap, WrapInput, WrapList } from '../../components/Wrap';
import { Input, LabelInput, inputStyle,multSelect } from '../../components/Input';
import { SearchBox, IconeSearch, ItemsSearch } from "../../components/Search";
import { Button, CancelarBtn } from "../../components/Button";
import { Container, TitleStyle2,Items} from './styles'
import { HeaderList, BodyList } from '../../components/TabelaCadastro';
import { Modal, CircularProgress } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '../../components/SharedComponents/table';
import { shade } from 'polished'
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { ThemeContext } from 'styled-components';
import { ERRORMESSAGE, SUCCESSMESSAGE, INFOMESSAGE } from '../../utils/types-messages';
import { useSnackbar } from 'notistack';
import { api } from '../../services/axios';
import { makeStyles } from '@material-ui/core/styles';
import { modalClose, ModalBox, StyledBox } from '../../components/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import MessageAlert from '../../components/MessageAlert';
import { useShowModal } from '../../contexts/SharedContext'
import { MySelect } from "../../components/InputSelect";
import Header from "../../components/Header"

export default function CadastroFormularios(props){
    const { enqueueSnackbar } = useSnackbar();
    const { colors, textTableColor } = useContext(ThemeContext);
    const [areas, setAreas] = useState([]);
    const [nomeConsulta, setNomeConsulta] = useState('');
    const [descricaoConsulta, setDescricaoConsulta] = useState('');
    const [tipoConsulta, setTipoConsulta] = useState('');
    const [loadDialog, setLoadDialog] = useState(false);
    const [idToModal, setIdToModal] = useState();
    const { setShowModalArea, showModalArea } = useShowModal();
    const [nome, setNome] = useState(null);
    const [descricao, setDescricao] = useState(null);
    const [tipo, setTipo] = useState(null);

    const useStyles = makeStyles({
        wrapBtnModal: {
          alignItems: 'flex-start'
        },
        containerModal: {
          boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
          borderRadius: '0'
        },
        headColModal: {
          backgroundColor: colors.surface,
          textTransform: 'uppercase',
          borderRadius: '0',
          fontSize: '14px'
        }
    });
    const classes = useStyles();

    const tipoOptions = [
      { value: "Público", label: "Público" },
      { value: "Restrito", label: "Restrito" },
      { value: "Membros", label: "Membros" },
      { value: "Aprendizes", label: "Aprendizes" },
      { value: "Amigos", label: "Amigos" }
    ]

    const handleTipo = (item) => {
      setTipo(item);
    }

    const handleTipoConsulta = (item) => {
      setTipoConsulta(item);
    }

    async function fetchSearch(showSnack=true) {
        if (showSnack) {const key = enqueueSnackbar('Pesquisando Formulários ...', INFOMESSAGE);};
        try {
            let params = ''
            if (nomeConsulta !== '') params += `&nome=${nomeConsulta}`
            if (descricaoConsulta !== '') params += `&descricao=${descricaoConsulta}`
            if ((tipoConsulta !== null) && (tipoConsulta.value !== undefined) && (tipoConsulta.value !== '')){
              params += `&tipo=${tipoConsulta.value}`
            }
            setLoadDialog(true);
            const { data } = await api.get(`formulario?` + params);
            setAreas(data);
            setLoadDialog(false);
        } catch (error) {
            enqueueSnackbar("Erro ao buscar Formulários", ERRORMESSAGE);
            setLoadDialog(false);
        }
    }

    function clearSearch() {
        setAreas([]);
        setNomeConsulta('');
        setDescricaoConsulta('');
        setTipoConsulta([]);
    }

    function openModal(action, item) {
        clearInclude();
        if (action === "insert") {
            setShowModalArea({ show: true, title: 'INCLUIR FORMULÁRIO', action: 'inserir' });
        }
        else {
            setShowModalArea({ show: true, title: 'ALTERAR FORMULÁRIO', action: 'editar', item: item });
        }
            
    }

    function clearInclude() {
        setNome(null);
        setDescricao(null);
        setTipo([]);
        
    }

    const handleClose = () => {
        setShowModalArea({ show: false });
      };

    async function sendFormulario() {

        const key = enqueueSnackbar('Cadastrando Formulário ...', INFOMESSAGE);
        
        try {
    
          const valoresInseridos = {
            nome: nome,
            descricao: descricao,
            tipo: tipo.value
          };
    
          setLoadDialog(true);
          const { data } = await api.post("/formulario/", valoresInseridos);
          if (data.message!=null) throw {message: data.message}
          enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
          setShowModalArea({ show: false });
          clearInclude();
          fetchSearch(false);
          setLoadDialog(false);
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
          setLoadDialog(false);
        }
      };
    
      async function editFormulario(id) {
    
        const key = enqueueSnackbar('Alterando Formulário ...', INFOMESSAGE);
    
        try {
    
          const valoresEditados = {
            nome: nome,
            descricao: descricao,
            tipo: tipo.value
          };
    
          setLoadDialog(true);
          const { data } = await api.put("/formulario/" + id, valoresEditados);
          if (data.message!=null) throw {message: data.message}
          enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
          setShowModalArea({ show: false });
          clearInclude();
          fetchSearch(false);
          setLoadDialog(false);
    
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
          setLoadDialog(false);
        }
      };

      useEffect(() => {
        if (showModalArea.action === 'editar') {
            setIdToModal(showModalArea.item.id);
            setNome(showModalArea.item.nome);
            setDescricao(showModalArea.item.descricao);
            setTipo({value:`${showModalArea.item.tipo}`, label:`${showModalArea.item.tipo}`});
        }
      }, [showModalArea.action])


    async function deleteFormulario(id) {
        try {
          var resposta = window.confirm("Deseja remover esse registro?");
          if (resposta) {
            const key = enqueueSnackbar('Excluindo Formulário ...', INFOMESSAGE);
            const { data } = await api.delete("/formulario/" + id);
            if ((data.success !== undefined) && (!data.success))
            {
              throw new Error(data.message)
            }
            enqueueSnackbar(<MessageAlert message={"Formulário excluído com sucesso!"} />, SUCCESSMESSAGE);
            fetchSearch(false);
          }
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
        }
    };
    

    const bodyFormulario = (
        <StyledBox className={'disableFocus alert-modal'}>
          <TitleStyle2>
            {showModalArea.title}
            <CancelIcon
              style={modalClose}
              color={colors.error}
              size="20"
              title="Fechar"
              onClick={() => handleClose()}
            />
          </TitleStyle2>
          <ModalBox>
            <Wrap>
              <WrapInput style={{ position: 'relative', width: '30%'}}>
                <LabelInput>Nome <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <Input
                  placeholder="Inserir Nome"
                  style={inputStyle}
                  maxLength="100"
                  type="text"
                  name="nome"
                  onChange={e => setNome(e.target.value)}
                  value={nome}
                />
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput style={{ position: 'relative', width: '30%'}}>
                <LabelInput>Descrição <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <Input
                  placeholder="Inserir Descrição"
                  style={inputStyle}
                  maxLength="255"
                  type="text"
                  name="descricao"
                  onChange={e => setDescricao(e.target.value)}
                  value={descricao}
                />
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput style={{ position: 'relative', width: '30%'}}>
                <LabelInput>Tipo <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <MySelect
                  styles={multSelect}
                  placeholder={'Selecione o Tipo'}
                  value={tipo}
                  isClearable={true}
                  isSearchable={true}
                  onChange={handleTipo}
                  options={tipoOptions}
                />
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput className="wrapInputModal">
                <Items>
                  <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
                  <Button type="button" onClick={() => showModalArea.action === 'inserir' ? sendFormulario() : editFormulario(idToModal)} > Salvar </Button>
                </Items>
              </WrapInput>
            </Wrap>
          </ModalBox>
        </StyledBox>
      );
    

    const modalLoad = (
        <div className={'disabeFocus alert-modal'}>
            <WrapInput className="alertText" style={{ alignItems: 'center' }}>
            <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
            </WrapInput>
        </div>
    );

    return (
        <Container>
          <Header/>
            <Modal open={showModalArea.show}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
            {bodyFormulario}
            </Modal>
            <SearchBox style={{ margin: '20px 0 20px 0' }}>
                <TitleStyle2>Pesquisa de Formulários</TitleStyle2>
                <Wrap>
                <WrapInput style={{ width: '20%', paddingRight: '5px' }}> <LabelInput> Nome </LabelInput>
                    <Input
                        placeholder="Filtrar por Nome"
                        style={inputStyle}
                        maxLength="100"
                        type="text"
                        name="nome"
                        onChange={e => setNomeConsulta(e.target.value)}
                        value={nomeConsulta}
                    />
                </WrapInput>
                <WrapInput style={{ width: '20%', paddingRight: '5px' }}> <LabelInput> Descrição </LabelInput>
                    <Input
                        placeholder="Filtrar por Descrição"
                        style={inputStyle}
                        maxLength="100"
                        type="text"
                        name="descricao"
                        onChange={e => setDescricaoConsulta(e.target.value)}
                        value={descricaoConsulta}
                    />
                </WrapInput>
                <WrapInput style={{ width: '10%', paddingRight: '1px' }}> <LabelInput> Tipo </LabelInput>
                  <MySelect
                    styles={multSelect}
                    placeholder={'Filtrar por Tipo...'}
                    value={tipoConsulta}
                    isClearable={true}
                    isSearchable={true}
                    onChange={handleTipoConsulta}
                    options={tipoOptions}
                  />
                </WrapInput>
                <WrapInput style={{ width:'30%', marginTop: '10px' }}>
                    <ItemsSearch>
                    <IconeSearch size={25} title="Pesquisar" onClick={() => fetchSearch(true)} />
                    <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' onClick={() => openModal('insert')}>&nbsp;Novo<AddIcon></AddIcon></Button>
                    <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
                    </ItemsSearch>
                </WrapInput>
                </Wrap>
            </SearchBox>
            <WrapList>
                <HeaderList>
                <span style={{ marginLeft: '20px' }}>Formulários</span>
                </HeaderList>
                {
                <BodyList>
                    <TableContainer>
                        <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">NOME</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">DESCRIÇÃO</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">TIPO</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">AÇÕES</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody style={{ width: '100%' }}>
                            {
                                areas && areas.map((row, index) => {
                                return (
                                    <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.nome}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.descricao}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.tipo}</TableCell>
                                        <TableCell
                                            style={{ color: textTableColor, border: 'none', display: 'flex', justifyContent: 'center' }}
                                            align="center">
                                            <AiOutlineEdit
                                            onClick={() => openModal('update',row)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="Editar"
                                            />          
                                            <AiOutlineDelete
                                            onClick={() => deleteFormulario(row.id)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="excluir"
                                            />                
                                        </TableCell>
                                    </TableRow>
                                    )
                                    })
                                }
                                </TableBody>
                        </Table>
                    </TableContainer >
 
                </BodyList>  
                }
            </WrapList>
            <Modal open={loadDialog}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {modalLoad}
            </Modal>
        </Container>
    )
}