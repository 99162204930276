import React, { useState, useContext, useEffect } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '../../components/SharedComponents/table';
import { useShowModal } from "../../contexts/SharedContext";
import { modalClose, StyledBox,} from "../../components/Modal";
import { Modal } from "@material-ui/core";
import { Container, TitleStyle2, ModalBox2, Items } from "./styles";
import Header from "../../components/Header";
import { HeaderList, BodyList } from "../../components/TabelaCadastro";
import { ThemeContext } from "styled-components";
import { Button, CancelarBtn } from "../../components/Button";
import { Wrap, WrapInput, WrapList } from "../../components/Wrap";
import { Input, LabelInput, inputStyle, multSelect,} from "../../components/Input";
import { SearchBox, IconeSearch, ItemsSearch } from "../../components/Search";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import { MySelect as MySelect2 } from "../../components/InputSelect";
import {  ERRORMESSAGE,  SUCCESSMESSAGE,  INFOMESSAGE,} from "../../utils/types-messages";
import { useSnackbar } from "notistack";
import { api } from "../../services/axios";
import MessageAlert from "../../components/MessageAlert";
import { makeStyles } from '@material-ui/core/styles';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { shade } from 'polished'

export default function CadastroTemaEstudo(props) {
  const { showModalTemaEstudo, setShowModalTemaEstudo } = useShowModal();
  const { enqueueSnackbar } = useSnackbar();
  const [temaEstudo, setTemaEstudo] = useState([]);
  
  const [nomeTemaEstudo, setNomeTemaEstudo] = useState("");
  const [codigoTemaEstudo, setcodigoTemaEstudo] = useState("");
  const [codigoTemaEstudoPesquisa, setcodigoTemaEstudoPesquisa] = useState("");
  const [descricaoTemaEstudo, setDescricaoTemaEstudo] = useState("");
  const [loadDialog, setLoadDialog] = useState(false);
  const [idToModal, setIdToModal] = useState();
  const { colors, textTableColor } = useContext(ThemeContext);
  
  const [ estudoPesquisar, setEstudoPesquisar ] = useState([{ id: 0, value: '', label: 'Selecione o Estudo...' }]) // id value
  const [ estudoPesquisarOptions, setEstudoPesquisarOptions ] = useState([]);

  const [ estudoCadastrar, setEstudoCadastrar ] = useState([]);
  const [ estudoCadastrarOptions, setEstudoCadastrarOptions] = useState([]);  

  const useStyles = makeStyles({
    wrapBtnModal: {
      alignItems: 'flex-start'
    },
    containerModal: {
      boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
      borderRadius: '0'
    },
    headColModal: {
      backgroundColor: colors.surface,
      textTransform: 'uppercase',
      borderRadius: '0',
      fontSize: '14px'
    }
  });
  const classes = useStyles();


  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await api.get('estudo');
        const temaEstudoOptionsValues = response.data.map((estudos) => {
          return  { id: estudos.id, value: estudos.id, label: estudos.nome}
      
        })

        setEstudoPesquisarOptions(temaEstudoOptionsValues)
        setEstudoCadastrarOptions(temaEstudoOptionsValues)
      } catch (error) {
        enqueueSnackbar(<MessageAlert message={error.message} />, ERRORMESSAGE)
      }
    };

    fetchData();
  }, []);

  const handleEstudos = (item) => {
    setEstudoPesquisar(item);

  };

  const handleEstudosCadastrar = (item) => {
    setEstudoCadastrar(item);

  };

  const handleClose = () => {
    setShowModalTemaEstudo({ show: false});
    setcodigoTemaEstudo("");
  };

  async function sendTemaEstudo() {
    const key = enqueueSnackbar("Cadastrando o Tema do Estudo ...", INFOMESSAGE);

    try {
      const valoresInseridos = {
        idEstudo: estudoCadastrar.value,
        codigo: codigoTemaEstudo,
        nome: nomeTemaEstudo,
        descricao: descricaoTemaEstudo,
      };
      
      setLoadDialog(true);
      const { data } = await api.post("/temaestudo/", valoresInseridos);
      if (data.message != null) throw { message: data.message };
      enqueueSnackbar(
        <MessageAlert message={"Dados salvos com sucesso!"} />,
        SUCCESSMESSAGE
      );
      setShowModalTemaEstudo({ show: false });
      //clearInclude();
      fetchSearch(false);
      setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  async function fetchSearch(showSnack = true) {
    if (showSnack) {const key = enqueueSnackbar('Pesquisando Temas de Estudo ...', INFOMESSAGE);};
    try {
        let params = ''
        if (estudoPesquisar !== '') params += `&idEstudo=${estudoPesquisar.id}`
        if(params === '&idEstudo=undefined') params = ''
        if (codigoTemaEstudoPesquisa !== '' ) params += `&codigo=${codigoTemaEstudoPesquisa}`
        if(params === '&codigo=null') params = ''
        setLoadDialog(true);
        const { data } = await api.get(`temaestudo?status=A` + params);

        if(data === ''){
          enqueueSnackbar('Nenhum tema de estudo encontrado', INFOMESSAGE)
        }
        setTemaEstudo(data);
        // setEstudoCadastrar(data)
        setLoadDialog(false);
    } catch (error) {
        enqueueSnackbar("Erro ao buscar os Temas de Estudo", ERRORMESSAGE);
        setLoadDialog(false);
    }

  }

  async function editTemaEstudo(id) {
    const key = enqueueSnackbar("Alterando Estudo ...", INFOMESSAGE);
    try {
      const valoresEditados = {
        idEstudo: estudoCadastrar.value,
        codigo: codigoTemaEstudo,
        nome: nomeTemaEstudo,
        descricao: descricaoTemaEstudo,
      };
      setLoadDialog(true);
      const { data } = await api.put("/temaestudo/" + id, valoresEditados);
      if (data.message != null) throw { message: data.message };
      enqueueSnackbar(
        <MessageAlert message={"Dados salvos com sucesso!"} />,
        SUCCESSMESSAGE
      );
      setShowModalTemaEstudo({ show: false });
      //clearInclude();
      fetchSearch(false);
      setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  async function deleteTemaEstudo(id) {
    try {
      var resposta = window.confirm("Deseja remover esse registro?");
      if (resposta) {
        const key = enqueueSnackbar('Excluindo Estudo ...', INFOMESSAGE);
        const { data } = await api.delete("/temaestudo/" + id);
        if ((data.success !== undefined) && (!data.success))
        {
          throw new Error(data.message)
        }
        enqueueSnackbar(<MessageAlert message={"Estudo excluído com sucesso!"} />, SUCCESSMESSAGE);
        fetchSearch(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
    }
};

  useEffect(() => {
    if (showModalTemaEstudo.action === 'editar') {
        setIdToModal(showModalTemaEstudo.item.id)
        setcodigoTemaEstudo(showModalTemaEstudo.item.codigo)
        setNomeTemaEstudo(showModalTemaEstudo.item.nome);
        setDescricaoTemaEstudo(showModalTemaEstudo.item.descricao);
        setEstudoCadastrar({value:`${showModalTemaEstudo.item.idEstudo}`, label:`${showModalTemaEstudo.item.nomeEstudo}`});
    }
  }, [showModalTemaEstudo.action])

  function openModal(action, item) {
    clearInclude(action);
    if (action === "insert") {
      setShowModalTemaEstudo({
        show: true,
        title: "INCLUIR TEMA",
        action: "inserir",
      });
    } else {
      setShowModalTemaEstudo({
        show: true,
        title: "ALTERAR TEMA",
        action: "editar",
        item: item,
      });
    }
  }

  function clearInclude(action) {

    if(action === 'update'){
      setcodigoTemaEstudo("");
      setNomeTemaEstudo(null);
      setDescricaoTemaEstudo(null);
    } else if(action === 'insert'){
      setcodigoTemaEstudo("");
      setNomeTemaEstudo(null);
      setDescricaoTemaEstudo(null);
      setEstudoCadastrar([]);
    }else{
      setcodigoTemaEstudoPesquisa("");
      setTemaEstudo(null);
      setEstudoPesquisar([{label: 'Selecione o Estudo...'}]);
    }
    
  }

  

  const body = (
    <StyledBox className={"disableFocus alert-modal"}>
      <TitleStyle2>
        {showModalTemaEstudo.title}
        <CancelIcon
          style={modalClose}
          size="20"
          title="Fechar"
          onClick={() => handleClose()}
        />
      </TitleStyle2>
      <ModalBox2>
        <Wrap>
          <WrapInput style={{ position: "relative", width: "10%" }}>
            <LabelInput>
              Código <span style={{ color: "#FF0000" }}>*</span>
            </LabelInput>
            <Input
              placeholder="Insira o Código"
              style={inputStyle}
              maxLength="5"
              type="text"
              name="codigoTemaEstudo"
              onChange={(e) =>setcodigoTemaEstudo(e.target.value.toLocaleUpperCase())}
              value={codigoTemaEstudo}
            />
          </WrapInput>
          <WrapInput style={{ position: "relative", width: "70%" }}>
            <LabelInput>
              Nome <span style={{ color: "#FF0000" }}>*</span>
            </LabelInput>
            <Input
              placeholder="Insira o nome do Tema do Estudo"
              style={inputStyle}
              maxLength="100"
              type="text"
              name="nomeTemaEstudo"
              onChange={(e) => setNomeTemaEstudo(e.target.value)}
              value={nomeTemaEstudo}
            />
          </WrapInput>
        </Wrap>
        <Wrap>
        <WrapInput style={{ position: "relative", width: "30%" }}>
            <LabelInput>Estudo<span style={{ color: "#FF0000" }}>*</span></LabelInput>
            <MySelect2
              styles={multSelect}
              placeholder={"Selecione o Estudo em que deseja inserir o Tema"}
              value={estudoCadastrar}
              isClearable={true}
              isSearchable={true}
              onChange={handleEstudosCadastrar}
              options={estudoCadastrarOptions}
            />
          </WrapInput>
        </Wrap>
        <Wrap>
          <WrapInput style={{ position: "relative", width: "100%" }}>
            <LabelInput>
              Descrição do Tema
            </LabelInput>
            <Input
              placeholder="Insira a Descrição"
              style={inputStyle}
              maxLength="255"
              type="text"
              name="descrição"
              onChange={(e) => setDescricaoTemaEstudo(e.target.value)}
              value={descricaoTemaEstudo}
              multiple="true"
            />
          </WrapInput>
        </Wrap>
        <Wrap>
          <WrapInput className="wrapInputModal">
            <Items>
              <CancelarBtn type="button" onClick={handleClose}>
                {" "}
                Cancelar{" "}
              </CancelarBtn>
              <Button
                type="button"
                onClick={() =>
                  showModalTemaEstudo.action === "inserir"
                    ? sendTemaEstudo()
                    : editTemaEstudo(idToModal)
                }
              >
                {" "}
                Salvar{" "}
              </Button>
            </Items>
          </WrapInput>
        </Wrap>
      </ModalBox2>
    </StyledBox>
  );

  return (
    <Container>
      <Header />
      <Modal
        open={showModalTemaEstudo.show}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <SearchBox style={{ margin: "20px 0 20px 0" }}>
        <TitleStyle2>Pesquisa de Temas de Estudo</TitleStyle2>
        <Wrap>
          <WrapInput style={{ width: '2%', paddingRight: '5px' }}> <LabelInput>Código</LabelInput>
            <Input
              placeholder="Pesquisar por Código"
              style={inputStyle}
              maxLength="50"
              type="text"
              name="codigo"
              onChange={e => setcodigoTemaEstudoPesquisa(e.target.value.toUpperCase())}
              value={codigoTemaEstudoPesquisa}
            />
          </WrapInput>
          <WrapInput style={{ position: "relative", width: "20%" }}>
            <LabelInput>Estudo</LabelInput>
            <MySelect2
              styles={multSelect}
              placeholder={"Selecione o estudo"}
              value={estudoPesquisar}
              isClearable={true}
              isSearchable={true}
              onChange={handleEstudos}
              options={estudoPesquisarOptions}
            />
          </WrapInput>
          
          
          <WrapInput style={{ marginTop: "10px" }}>
            <ItemsSearch>
              <IconeSearch size={25} title="Pesquisar" onClick={() => fetchSearch(true)} />
              <Button
                style={{ backgroundColor: "#3FC248" }}
                className="widthButton"
                onClick={() => openModal('insert')}
              >
                &nbsp;Novo<AddIcon></AddIcon>
              </Button>
              <Button className="widthButton" onClick={() => clearInclude()}>
                &nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon>
              </Button>
            </ItemsSearch>
          </WrapInput>
        </Wrap>
      </SearchBox>
      <WrapList>
                <HeaderList>
                <span style={{ marginLeft: '20px' }}>ESTUDO</span>
                </HeaderList>
                {
                <BodyList>
                    <TableContainer>
                        <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">ESTUDO</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">CÓDIGO</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">NOME</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">DESCRIÇÃO</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">AÇÕES</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody style={{ width: '100%' }}>
                            {
                                temaEstudo && temaEstudo.map((row, index) => {
                                  return (
                                    <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.nomeEstudo}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.codigo}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.nome}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.descricao}</TableCell>
                                        <TableCell
                                            style={{ color: textTableColor, border: 'none', display: 'flex', justifyContent: 'center' }}
                                            align="center">
                                            <AiOutlineEdit
                                              onClick={() => openModal('update',row)}
                                              style={{ cursor: "pointer", marginLeft: '5px'  }}
                                              color={colors.primary}
                                              size="22"
                                              title="Editar"
                                            />          
                                            <AiOutlineDelete
                                              onClick={() => deleteTemaEstudo(row.id)}
                                              style={{ cursor: "pointer", marginLeft: '5px'  }}
                                              color={colors.primary}
                                              size="22"
                                              title="Editar"
                                            />                
                                        </TableCell>
                                    </TableRow>
                                    )
                                    })
                                }
                                </TableBody>
                        </Table>
                    </TableContainer >
 
                </BodyList>  
                }
            </WrapList>
      
    </Container>
  );
}
