import React, { } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../pages/Login/index'
import CadastroPessoas from '../pages/CadastroPessoas';
import CadastroEstudos from '../pages/CadastroEstudos';
import CadastroTurmas from '../pages/CadastroTurmas';
import CadastroAreas from '../pages/CadastroAreas';
import CadastroPessoaArea from '../pages/CadastroPessoaArea';
import CadastroFormularios from '../pages/CadastroFormularios';
import CadastroPerguntas from '../pages/CadastroPerguntas';
import CadastroFuncionalidades from '../pages/CadastroFuncionalidades';
import CadastroPerfis from '../pages/CadastroPerfis';
import CadastroPlanoAula from '../pages/CadastroPlanoAula';
import RelatoriosPessoas from '../pages/RelatoriosPessoas';
import OptOut from '../pages/OptOut';
import Home from '../pages/Home';
import Sair from '../pages/Sair';
import { useTheme } from '../contexts/ThemeContext'
import { ThemeProvider } from 'styled-components'
import { SnackbarProvider } from 'notistack';
import { isAuthenticated } from '../services/helpers';
import CadastroTemaEstudo from '../pages/CadastroTemaEstudo';
import ManutencaoAlunoTurma from '../pages/ManutencaoAlunoTurma';

export default function App() {

const { theme } = useTheme();

const Authenticated = () => {
return isAuthenticated()
}

return (
    <ThemeProvider theme={theme}>
        <SnackbarProvider
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            maxSnack={1}>
            {
            }
        <BrowserRouter>
        <div className="App">
            <Routes>
                <Route exact path="/" element={<Login/>}/>
                <Route exact path="/login" element={<Login/>}/>
                <Route path="/cadastropessoas" element={Authenticated() ? <CadastroPessoas/> : <Login/>}/>
                <Route path="/cadastroestudos" element={Authenticated() ? <CadastroEstudos/> : <Login/>}/>
                <Route path="/cadastroturmas" element={Authenticated() ? <CadastroTurmas/> : <Login/>}/>
                <Route path="/cadastroareas" element={Authenticated() ? <CadastroAreas/> : <Login/>}/>
                <Route path="/cadastropessoaarea" element={Authenticated() ? <CadastroPessoaArea/> : <Login/>}/>
                <Route path="/cadastroformularios" element={Authenticated() ? <CadastroFormularios/> : <Login/>}/>
                <Route path="/cadastroperguntas" element={Authenticated() ? <CadastroPerguntas/> : <Login/>}/>
                <Route path="/cadastrofuncionalidades" element={Authenticated() ? <CadastroFuncionalidades/> : <Login/>}/>
                <Route path="/cadastroperfis" element={Authenticated() ? <CadastroPerfis/> : <Login/>}/>
                <Route path="/cadastrotemaestudo" element={Authenticated() ? <CadastroTemaEstudo/> : <Login/>}/>
                <Route path="/cadastroplanoaula" element={Authenticated() ? <CadastroPlanoAula/> : <Login/>}/>
                <Route path="/manutencaoalunoturma" element={Authenticated() ? <ManutencaoAlunoTurma/> : <Login/>}/>
                <Route path="/relatoriospessoas" element={Authenticated() ? <RelatoriosPessoas/> : <Login/>}/>
                <Route path="/optout" element={Authenticated() ? <OptOut/> : <Login/>}/>
                <Route path="/home" element={Authenticated() ? <Home/> : <Login/>}/>
                <Route path="/logout" element={Authenticated() ? <Sair/> : <Login/>}/>
            </Routes>
        </div>
        </BrowserRouter>
        </SnackbarProvider>
    </ThemeProvider>
);
}