import React, { useState, useEffect} from 'react'
import { Container, Form, FormInputs, Title, Logo, InputLogin, FormLogo, InputLoginParent,ButtonLogin } from './styles.js'
import logo from '../../assets/thelos-back.png'
import bg from '../../assets/fundo_web.png'
import Loader from 'react-spinners/ClipLoader';
import { LoginUser } from '../../services/helpers'

export default function Login(props) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [user,setUser] = useState('')
  const [password, setPassword] = useState('')
  const [showForm, setShowForm] = useState('translateY(-100vh)')

  useEffect(() => {
    setShowForm('translateY(0)')
  }, [])

  async function onSubmit(e){

    e.preventDefault();
    setError(false)
    setLoading(true)

    //let retorno = {success:true,message:'Usuário logado com sucesso'}
    let retorno = await LoginUser(password, user)
    if (retorno.success) {
      window.location = '/home'
      //window.location = 'http://thelos.org.br' 
      //setError('Usuário:'+ user + ' <=> Password:' + password)
    }
    else
    {
      setError(retorno.message)
    }
    
    setLoading(false)

  }

  return (
    <Container bg={bg}>
      <Form onSubmit={onSubmit} showForm={showForm}>
        {error && (
          <small
            style={{
              color: "#ddd",
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {error}
          </small>
        )}

        <FormLogo>
        {loading ? (
            <Loader sizeUnit={"px"} size={25} color={"#ddd"} loading={true} />
          ) : (
            <Logo href={"http://thelos.org.br"} img={logo} />
          )}          
        </FormLogo>
        <FormInputs>
          <Title> Login </Title>
          <InputLoginParent>
            <InputLogin 
              type="text"
              placeholder="Usuário"
              value={user}
              onChange={(e) => setUser(e.target.value)}
            />
          </InputLoginParent>
          <InputLoginParent>
            <InputLogin 
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputLoginParent>
          <ButtonLogin type="submit" className={'enable'}> Entrar </ButtonLogin>
        </FormInputs>
     </Form>
    </Container>

  );
}