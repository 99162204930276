import React, { useState, useContext, useEffect } from 'react';
import { Wrap, WrapInput, WrapList } from '../../components/Wrap';
import { Input, LabelInput, inputStyle } from '../../components/Input';
import { SearchBox, IconeSearch, ItemsSearch } from "../../components/Search";
import { Button, CancelarBtn } from "../../components/Button";
import { Container, TitleStyle2,Items} from './styles'
import { HeaderList, BodyList } from '../../components/TabelaCadastro';
import { Modal, CircularProgress } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '../../components/SharedComponents/table';
import { shade } from 'polished'
import { AiFillEdit, AiFillIdcard, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { ThemeContext } from 'styled-components';
import { ERRORMESSAGE, SUCCESSMESSAGE, INFOMESSAGE } from '../../utils/types-messages';
import { useSnackbar } from 'notistack';
import { api } from '../../services/axios';
import { makeStyles } from '@material-ui/core/styles';
import { modalClose, ModalBox, StyledBox } from '../../components/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import MessageAlert from '../../components/MessageAlert';
import { useShowModal } from '../../contexts/SharedContext'
import InputMask from 'react-input-mask';
import Header from "../../components/Header"



export default function CadastroEstudos(props){
    const { enqueueSnackbar } = useSnackbar();
    const { colors, textTableColor } = useContext(ThemeContext);
    const [estudos, setEstudos] = useState([]);
    const [codigoConsulta, setCodigoConsulta] = useState('');
    const [nomeConsulta, setNomeConsulta] = useState('');
    const [duracaoConsulta, setDuracaoConsulta] = useState('');
    const [codigo, setCodigo] = useState('');
    const [nome, setNome] = useState('');
    const [duracao, setDuracao] = useState('');
    const [descricao, setDescricao] = useState('');
    const [loadDialog, setLoadDialog] = useState(false);
    const [idToModal, setIdToModal] = useState();
    const { setShowModalEstudo, showModalEstudo } = useShowModal();

    const useStyles = makeStyles({
        wrapBtnModal: {
          alignItems: 'flex-start'
        },
        containerModal: {
          boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
          borderRadius: '0'
        },
        headColModal: {
          backgroundColor: colors.surface,
          textTransform: 'uppercase',
          borderRadius: '0',
          fontSize: '14px'
        }
    });
    const classes = useStyles();

    async function fetchSearch(showSnack=true) {
        if (showSnack) {const key = enqueueSnackbar('Pesquisando Estudos ...', INFOMESSAGE);};
        try {
            let params = ''
            if (codigoConsulta !== '') params += `&codigo=${codigoConsulta}`
            if (nomeConsulta !== '') params += `&nome=${nomeConsulta}`
            if (duracaoConsulta !== '') params += `&duracao=${duracaoConsulta}`
            setLoadDialog(true);
            const { data } = await api.get(`estudo?status=A` + params);
            setEstudos(data);
            setLoadDialog(false);
        } catch (error) {
            enqueueSnackbar("Erro ao buscar Estudos", ERRORMESSAGE);
            setLoadDialog(false);
        }
    }

    function clearSearch() {
        setEstudos([]);
        setCodigoConsulta('');
        setNomeConsulta('');
        setDuracaoConsulta('');
    }

    function openModal(action, item) {
        clearInclude();
        if (action === "insert") {
            setShowModalEstudo({ show: true, title: 'INCLUIR ESTUDO', action: 'inserir' });
        }
        else {
            setShowModalEstudo({ show: true, title: 'ALTERAR ESTUDO', action: 'editar', item: item });
        }
            
    }

    function clearInclude() {
        setCodigo(null);
        setNome(null);
        setDescricao(null);
        setDuracao(null);
        
    }

    const handleClose = () => {
        setShowModalEstudo({ show: false });
      };

    async function sendEstudo() {

        const key = enqueueSnackbar('Cadastrando Estudo ...', INFOMESSAGE);
        
        try {
    
          const valoresInseridos = {
            codigo: codigo,
            nome: nome,
            descricao: descricao,
            duracao: duracao            
          };
    
          setLoadDialog(true);
          const { data } = await api.post("/estudo/", valoresInseridos);
          if (data.message!=null) throw {message: data.message}
          enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
          setShowModalEstudo({ show: false });
          clearInclude();
          fetchSearch(false);
          setLoadDialog(false);
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
          setLoadDialog(false);
        }
      };
    
      async function editEstudo(id) {
    
        const key = enqueueSnackbar('Alterando Estudo ...', INFOMESSAGE);
    
        try {
    
          const valoresEditados = {
            codigo: codigo,
            nome: nome,
            descricao: descricao,
            duracao: duracao
          };
    
          setLoadDialog(true);
          const { data } = await api.put("/estudo/" + id, valoresEditados);
          if (data.message!=null) throw {message: data.message}
          enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
          setShowModalEstudo({ show: false });
          clearInclude();
          fetchSearch(false);
          setLoadDialog(false);
    
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
          setLoadDialog(false);
        }
      };

      useEffect(() => {
        if (showModalEstudo.action === 'editar') {
            setIdToModal(showModalEstudo.item.id);
            setCodigo(showModalEstudo.item.codigo);
            setNome(showModalEstudo.item.nome);
            setDescricao(showModalEstudo.item.descricao);
            setDuracao(showModalEstudo.item.duracao);
        }
      }, [showModalEstudo.action])


    async function deleteEstudo(id) {
        try {
          var resposta = window.confirm("Deseja remover esse registro?");
          if (resposta) {
            const key = enqueueSnackbar('Excluindo Estudo ...', INFOMESSAGE);
            const { data } = await api.delete("/estudo/" + id);
            if ((data.success !== undefined) && (!data.success))
            {
              throw new Error(data.message)
            }
            enqueueSnackbar(<MessageAlert message={"Estudo excluído com sucesso!"} />, SUCCESSMESSAGE);
            fetchSearch(false);
          }
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
        }
    };
    

    const bodyEstudo = (
        <StyledBox className={'disableFocus alert-modal'}>
          <TitleStyle2>
            {showModalEstudo.title}
            <CancelIcon
              style={modalClose}
              color={colors.error}
              size="20"
              title="Fechar"
              onClick={() => handleClose()}
            />
          </TitleStyle2>
          <ModalBox>
            <Wrap>
              <WrapInput style={{ position: 'relative', width: '10%'}}>
                <LabelInput>Código <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <Input
                  placeholder="Inserir Código"
                  style={inputStyle}
                  maxLength="5"
                  type="text"
                  name="codigo"
                  onChange={e => setCodigo(e.target.value.toLocaleUpperCase())}
                  value={codigo}
                />
              </WrapInput>
              <WrapInput style={{ position: 'relative', width: '70%'}}>
                <LabelInput>Nome <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <Input
                  placeholder="Inserir Nome"
                  style={inputStyle}
                  maxLength="100"
                  type="text"
                  name="nome"
                  onChange={e => setNome(e.target.value)}
                  value={nome}
                />
              </WrapInput>
            </Wrap>
            <Wrap>
            <WrapInput style={{ position: 'relative', width: '10%'}}>
                <LabelInput>Duração <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <InputMask
                  mask="99999"
                  maskChar=""
                  placeholder="Inserir Duração"
                  style={inputStyle}
                  type="text"
                  name="duracao"
                  onChange={e => setDuracao(e.target.value)}
                  value={duracao}
                />
              </WrapInput>
              <WrapInput style={{ position: 'relative', width: '70%'}}>
                <LabelInput>Descrição <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <Input
                  placeholder="Inserir Descrição"
                  style={inputStyle}
                  maxLength="255"
                  type="text"
                  name="descrição"
                  onChange={e => setDescricao(e.target.value)}
                  value={descricao}
                  multiple="true"
                />
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput className="wrapInputModal">
                <Items>
                  <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
                  <Button type="button" onClick={() => showModalEstudo.action === 'inserir' ? sendEstudo() : editEstudo(idToModal)} > Salvar </Button>
                </Items>
              </WrapInput>
            </Wrap>
          </ModalBox>
        </StyledBox>
      );
    

    const modalLoad = (
        <div className={'disabeFocus alert-modal'}>
            <WrapInput className="alertText" style={{ alignItems: 'center' }}>
            <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
            </WrapInput>
        </div>
    );

    return (
        <Container>
          <Header/>
            <Modal open={showModalEstudo.show}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
            {bodyEstudo}
            </Modal>
            <SearchBox style={{ margin: '20px 0 20px 0' }}>
                <TitleStyle2>Pesquisa de Estudos</TitleStyle2>
                <Wrap>
                <WrapInput style={{ width: '13%', paddingRight: '5px' }}> <LabelInput> Código</LabelInput>
                    <Input
                    placeholder="Pesquisar por Código"
                    style={inputStyle}
                    maxLength="50"
                    type="text"
                    name="codigo"
                    onChange={e => setCodigoConsulta(e.target.value.toUpperCase())}
                    value={codigoConsulta}
                    />
                </WrapInput>
                <WrapInput style={{ width: '40%', paddingRight: '1px' }}> <LabelInput> Nome </LabelInput>
                    <Input
                    placeholder="Pesquisar por Nome"
                    style={inputStyle}
                    maxLength="40"
                    type="text"
                    name="nome"
                    onChange={e => setNomeConsulta(e.target.value)}
                    value={nomeConsulta}
                    />
                </WrapInput>
                <WrapInput style={{ width: '13%', paddingRight: '5px' }}> <LabelInput> Duração </LabelInput>
                    <Input
                    placeholder="Pesquisar por Duração"
                    style={inputStyle}
                    maxLength="40"
                    type="text"
                    name="duracao"
                    onChange={e => setDuracaoConsulta(e.target.value)}
                    value={duracaoConsulta}
                    />
                </WrapInput>
                <WrapInput style={{ width:'25%', marginTop: '10px' }}>
                    <ItemsSearch>
                    <IconeSearch size={25} title="Pesquisar" onClick={() => fetchSearch(true)} />
                    <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' onClick={() => openModal('insert')}>&nbsp;Novo<AddIcon></AddIcon></Button>
                    <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
                    </ItemsSearch>
                </WrapInput>
                </Wrap>
            </SearchBox>
            <WrapList>
                <HeaderList>
                <span style={{ marginLeft: '20px' }}>ESTUDOS  </span>
                </HeaderList>
                {
                <BodyList>
                    <TableContainer>
                        <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">CÓDIGO</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">NOME</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">DESCRIÇÃO</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">DURAÇÃO</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">AÇÕES</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody style={{ width: '100%' }}>
                            {
                                estudos && estudos.map((row, index) => {
                                return (
                                    <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.codigo}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.nome}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.descricao}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.duracao}</TableCell>
                                        <TableCell
                                            style={{ color: textTableColor, border: 'none', display: 'flex', justifyContent: 'center' }}
                                            align="center">
                                            <AiOutlineEdit
                                            onClick={() => openModal('update',row)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="Editar"
                                            />          
                                            <AiOutlineDelete
                                            onClick={() => deleteEstudo(row.id)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="Editar"
                                            />                
                                        </TableCell>
                                    </TableRow>
                                    )
                                    })
                                }
                                </TableBody>
                        </Table>
                    </TableContainer >
 
                </BodyList>  
                }
            </WrapList>
            <Modal open={loadDialog}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {modalLoad}
            </Modal>
        </Container>
    )
}