import React from 'react'

import Menu from '../Menu'

import './header.css'

const Header = () => (
    <header className="app-header">
        <img className="app-header__logo" src='thelos.png'/>
        <Menu />
    </header>
)

export default Header