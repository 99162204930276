import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '../SharedComponents/table';
import { ThemeContext } from 'styled-components'
import { shade } from 'polished'
import { AiTwotoneDelete, AiOutlinePhone, AiOutlineIdcard, AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useShowModal } from '../../contexts/SharedContext'
import { api } from '../../services/axios'
import { ERRORMESSAGE, SUCCESSMESSAGE, INFOMESSAGE } from '../../utils/types-messages'
import { useSnackbar } from 'notistack';
import { TitleStyle2,ModalBox2, Items } from "../../pages/CadastroPessoas/styles";
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import Modal from "@material-ui/core/Modal";
import { Wrap, WrapInput, WrapInputSmall } from '../../components/Wrap';
import { modalClose, modalStyle, ModalBox, StyledBox } from '../../components/Modal';
import { Input, LabelInput, inputStyle, multSelect } from '../../components/Input';
import { MySelect } from "../../components/InputSelect";
import { Button, CancelarBtn } from "../../components/Button";
import MessageAlert from '../../components/MessageAlert';
import { tint } from "polished";
import InputMask from 'react-input-mask';
import TelefoneBrasileiroInput from "react-telefone-brasileiro";
import {GetSessionUser} from '../../services/helpers';


export  function TableCadastroPessoa({pessoas}) {

  const { colors, textTableColor } = useContext(ThemeContext);
  const { setShowModalPessoa } = useShowModal();
  const [tableEnderecos, setTableEnderecos] = useState([]);
  const [enderecoDialog, setEnderecoDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [getNome, setGetNome] = useState([]);
  const { setShowModalEndereco, showModalEndereco } = useShowModal();
  const [logradouro, setLogradouro] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [numero, setNumero] = useState(null);
  const [complemento, setComplemento] = useState(null);
  const [bairro, setBairro] = useState(null);
  const [cidade, setCidade] = useState(null);
  const [estado, setEstado] = useState(null);
  const [pais, setPais] = useState(null);
  const [cep, setCep] = useState(null);
  const [idToModal, setIdToModal] = useState();
  const [idPessoa, setIdPessoa] = useState();
  const [tableTelefones, setTableTelefones] = useState([]);
  const [telefoneDialog, setTelefoneDialog] = useState(false);
  const [usuarioDialog, setUsuarioDialog] = useState(false);
  const [tipoTelefone, setTipoTelefone] = useState(null);
  const [numeroTelefone, setNumeroTelefone] = useState(null);
  const [ddd, setDDD] = useState(null);
  const [ddi, setDDI] = useState(null);
  const [idTelefone, setIdTelefone] = useState(null);
  const [login, setLogin] = useState(null);
  const [password, setPassword] = useState(null);
  const [perfilOptions, setPerfilOptions] = useState([]);
  const [idUsuario, setIdUsuario] = useState();
  const [perfil, setPerfil] = useState();
  const [nomePerfil,setNomePerfil] = useState(null);

  const GetUserSession = () => {
    const {perfil} = GetSessionUser();
    setNomePerfil(perfil);
  }

  useEffect(() => {
    GetUserSession();
  }, []);  

  const useStyles = makeStyles({
    wrapBtnModal: {
      alignItems: 'flex-start'
    },
    containerModal: {
      boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
      borderRadius: '0'
    },
    headColModal: {
      backgroundColor: colors.surface,
      textTransform: 'uppercase',
      borderRadius: '0',
      fontSize: '14px'
    }
  });

  const classes = useStyles();

  function openModal(item, action, items) {
    if (action === 'update') {
      setShowModalPessoa({ show: true, title: 'EDITAR PESSOA', action: 'editar', item: item });
    } else {
      //setIdToEdit(item.id)
      //setNameEdit(item.name);
      //setFamilyNameEdit(item.familyName)
      //setDeleteDialog(true);
    }
  }

  function openModalEdEnd(item, action, items) {
    if (action === 'update') {
      setShowModalEndereco({ show: true, title: 'EDITAR ENDEREÇO', action: 'editar', item: item });
    }
    else {
        clearInclude();
        setShowModalEndereco({ show: true, title: 'INCLUIR ENDEREÇO', action: 'inserir' });
    }
  }
  function editTelefone(item,action) {
    if (action === 'update') {
      setIdTelefone(item.id);
      setDDD(item.ddd);
      setDDI(item.ddi);
      setNumeroTelefone(item.numero);
      setTipoTelefone({value:`${item.tipo}`, label:`${item.tipo}`});
    }
  }

  function openModalEndereco(item, items) {
    setTableEnderecos([]);
    getEndereco(item.id);
    setGetNome(item);
    setEnderecoDialog(true);
    setIdPessoa(item.id);
  }

  function openModalTelefone(item) {
    clearTelefone();
    setTableTelefones([]);
    getTelefone(item.id);
    setGetNome(item);
    setTelefoneDialog(true);
    setIdPessoa(item.id);
  }

  function openModalUsuario(item) {
    setLogin(null);
    setPassword(null);
    setPerfil([]);
    setIdUsuario(null);
    getPerfis();
    getUsuarioPessoa(item.id);
    setGetNome(item);
    setUsuarioDialog(true);
    setIdPessoa(item.id);
  }

  function clearTelefone() {
    setDDI('');
    setDDD('');
    setNumeroTelefone('');
    setTipoTelefone([]);
    setIdTelefone('');
  }

  function clearInclude(){
    setTipo([]);
    setLogradouro(null);
    setNumero(null);
    setComplemento(null);
    setEstado(null);
    setCidade(null);
    setPais(null);
    setCep(null);
    setBairro(null);
  }

  async function getPerfis() {
    try {
      const { data } = await api.get("/perfil?status=A");
      if (data) {
        setPerfilOptions(data);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar perfis", ERRORMESSAGE);
    }
  }

  async function getUsuarioPessoa(idPessoa) {
    try {
      const { data } = await api.get("/usuario/" + idPessoa);
      if (data) {
        setLogin(data.login);
        setIdUsuario(data.id);
        setPerfil({value:`${data.perfil.id}`, label:`${data.perfil.nome}`});
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuário", ERRORMESSAGE);
    }
  }

  async function getEndereco(idPessoa) {
    //const key = enqueueSnackbar('Pesquisando Pessoas ...', INFOMESSAGE)
    try {
      const { data } = await api.get("/enderecopessoa/" + idPessoa);
      if (data) {
        setTableEnderecos(data);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar endereços", ERRORMESSAGE);
    }
  }

  async function getTelefone(idPessoa) {
    //const key = enqueueSnackbar('Pesquisando Pessoas ...', INFOMESSAGE)
    try {
      const { data } = await api.get("/telefonepessoa/" + idPessoa);
      if (data) {
        setTableTelefones(data);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar telefones", ERRORMESSAGE);
    }
  }

  const handleClose2 = () => {
    setEnderecoDialog(false);
  };

  const handleClose3 = () => {
    setShowModalEndereco({ show: false });
  };

  const handleCloseTelefone = () => {
    setTelefoneDialog(false);
  };

  const handleCloseUsuario = () => {
    setUsuarioDialog(false);
  };

  async function saveTelefone() {

    const key = enqueueSnackbar('Salvando Telefone ...', INFOMESSAGE);
    try {
      const valoresSalvar = {
        idPessoa: idPessoa,
        tipo: tipoTelefone.value,
        ddi: ddi,
        ddd: ddd,
        numero: numeroTelefone
      }

      if (idTelefone === '') {
        const { data } = await api.post("/telefonepessoa/", valoresSalvar);
      }
      else {
        const { data } = await api.put("/telefonepessoa/" + idTelefone, valoresSalvar);
      }
      getTelefone(idPessoa);
      clearTelefone();
      enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);

    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
    }
  }

  async function saveUsuario() {
    const key = enqueueSnackbar('Salvando Usuário ...', INFOMESSAGE);
    try {
      const valoresSalvar = {
        idPessoa: idPessoa,
        login: login,
        password: password,
        idPerfil: perfil.value,
      }

      if ((idUsuario === null) || (idUsuario === '')) {
        const { data } = await api.post("/usuario/", valoresSalvar);

        if ((data.success !== undefined) && (!data.success))
        {
          throw new Error(data.message)
        }
      }
      else {
        const { data } = await api.put("/usuario/" + idUsuario, valoresSalvar);

        if ((data.success !== undefined) && (!data.success))
        {
          throw new Error(data.message)
        }
      }
      
      enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
      handleCloseUsuario();

    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
    }
  }

  async function deleteTelefone(id) {
    try {
      var resposta = window.confirm("Deseja remover esse registro?");
      if (resposta) {
        const key = enqueueSnackbar('Excluindo Telefone ...', INFOMESSAGE);
        const { data } = await api.delete("/telefonepessoa/" + id);
        enqueueSnackbar(<MessageAlert message={"Telefone excluído com sucesso!"} />, SUCCESSMESSAGE);
        getTelefone(idPessoa);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
    }
  };

  async function createEndereco() {
    const key = enqueueSnackbar('Cadastrando Endereço ...', INFOMESSAGE);    
    try {

      const valoresInseridos = {
        idPessoa: idPessoa,
        tipo: tipo.value,
        logradouro: logradouro,
        numero: numero,
        complemento: complemento,
        bairro: bairro,
        cidade: cidade,
        estado: estado,
        pais: pais,
        cep: cep
      };

      //setLoadDialog(true);
      const { data } = await api.post("/enderecopessoa/", valoresInseridos);
      enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
      setShowModalEndereco({ show: false });
      getEndereco(idPessoa);
      clearInclude();
      //setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      //setLoadDialog(false);
    }
  };

  async function updateEndereco(id) {
    const key = enqueueSnackbar('Alterando Endereço ...', INFOMESSAGE);    
    try {

      const valoresAlterados = {
        idPessoa: idPessoa,
        tipo: tipo.value,
        logradouro: logradouro,
        numero: numero,
        complemento: complemento,
        bairro: bairro,
        cidade: cidade,
        estado: estado,
        pais: pais,
        cep: cep
      };

      //setLoadDialog(true);
      const { data } = await api.put("/enderecopessoa/" + id, valoresAlterados);
      enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
      setShowModalEndereco({ show: false });
      getEndereco(idPessoa);
      clearInclude();
      //setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      //setLoadDialog(false);
    }
  };

  async function deleteEndereco(id) {
    try {
      var resposta = window.confirm("Deseja remover esse registro?");
      if (resposta) {
        const key = enqueueSnackbar('Excluindo Endereço ...', INFOMESSAGE);
        const { data } = await api.delete("/enderecopessoa/" + id);
        enqueueSnackbar(<MessageAlert message={"Endereço excluído com sucesso!"} />, SUCCESSMESSAGE);
        getEndereco(idPessoa);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      //setLoadDialog(false);
    }
  };

  useEffect(() => {
    if (showModalEndereco.action === 'editar') {
      setLogradouro(showModalEndereco.item.logradouro);
      setTipo({value:`${showModalEndereco.item.tipo}`, label:`${showModalEndereco.item.tipo}`});
      setNumero(showModalEndereco.item.numero);
      setComplemento(showModalEndereco.item.complemento);
      setBairro(showModalEndereco.item.bairro);
      setCidade(showModalEndereco.item.cidade);
      setEstado(showModalEndereco.item.estado);
      setPais(showModalEndereco.item.pais);
      setCep(showModalEndereco.item.cep);
      setIdToModal(showModalEndereco.item.id);
      setIdPessoa(showModalEndereco.item.idPessoa);
    } 
  }, [showModalEndereco.action]);

  const tipoOptions = [
    { value: "Residencial", label: "Residencial" },
    { value: "Comercial", label: "Comercial" },
    { value: "Cobrança", label: "Cobrança" },
    { value: "Outro", label: "Outro" }
  ];

  const tipoTelefoneOptions = [
    { value: "Residencial", label: "Residencial" },
    { value: "Celular/WhatsApp", label: "Celular/WhatsApp" },
    { value: "Comercial", label: "Comercial" },
    { value: "Outro", label: "Outro" }
  ];

  const handleTipo = (item) => {
    setTipo(item);
  }

  const handleTipoTelefone = (item) => {
    setTipoTelefone(item);
  }

  const handlePerfil = (item) => {
    setPerfil(item);
  }

  const modalEndereco = (
    <div className={'disabeFocus alert-modal'} style={modalStyle}>
      <TitleStyle2 className="TitleStyle2">
        Endereços de {getNome.nome}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose2()}
        />
      </TitleStyle2>
      <ModalBox style={{ overflow: 'auto', height: '400px' }}>
        <Wrap>
          <TableContainer className={classes.containerModal}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headColModal} align="center">Tipo</TableCell>
                  <TableCell className={classes.headColModal} align="center">Endereço</TableCell>
                  <TableCell className={classes.headColModal} align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ width: '100%', backgroundColor: 'white' }}>
                {
                  tableEnderecos && tableEnderecos.map((row, index) => {
                    return (
                      <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                        <TableCell align="center">
                          {row.tipo}
                        </TableCell>
                        <TableCell align="center">
                          {
                            row.logradouro + ", " + row.numero + " - " + row.complemento +
                            " - " + row.bairro + " - " + row.cidade + "/" + row.estado
                          }
                        </TableCell>
                        <TableCell align="center">
                          <FiEdit
                            onClick={() => openModalEdEnd(row, 'update')}
                            style={{ cursor: "pointer" }}
                            color={colors.primary}
                            size="20"
                            title="Editar"
                          />        
                        
                          <AiTwotoneDelete
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                            color={tint(0.1, colors.error)}
                            size="20"
                            title="Remover"
                            onClick={() => deleteEndereco(row.id)}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Wrap>
        <Wrap>
          <WrapInput className="wrapInputModal">
            <Items>
              <CancelarBtn className='widthButton' type="button" onClick={handleClose2}> Sair </CancelarBtn>
              <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' onClick={() => openModalEdEnd('save')}>&nbsp;Novo<AddIcon></AddIcon></Button>
            </Items>
          </WrapInput>
        </Wrap>
      </ModalBox>
    </div>
  );

  const bodyEndereco = (
    <StyledBox className={'disableFocus alert-modal'}>
      <TitleStyle2>
        {showModalEndereco.title}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose3()}
        />
      </TitleStyle2>
      <ModalBox2>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInputSmall style={{ position: 'relative', width: '15%' }}>
            <LabelInput>Tipo <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect
              styles={multSelect}
              placeholder={'Opções...'}
              value={tipo}      
              onChange={handleTipo}
              options={tipoOptions}        
            />
          </WrapInputSmall>
          <WrapInput style={{ position: 'relative', width: '47%', marginLeft: '0px' }}>
            <LabelInput>Logradouro <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <Input
              placeholder="Inserir Logradouro"
              style={inputStyle}
              maxLength="150"
              type="text"
              name="logradouro"
              onChange={e => setLogradouro(e.target.value)}
              value={logradouro}
            />
          </WrapInput>
          <WrapInputSmall style={{ position: 'relative', width: '10%', marginLeft: '0px' }}>
            <LabelInput>Número <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <Input
              placeholder="Número"
              style={inputStyle}
              maxLength="10"
              type="text"
              name="número"
              onChange={e => setNumero(e.target.value)}
              value={numero}
            />
          </WrapInputSmall>
        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInputSmall style={{ position: 'relative', width: '35%', marginLeft: '0px' }}>
            <LabelInput>Complemento</LabelInput>
            <Input
              placeholder="Inserir Complemento"
              style={inputStyle}
              maxLength="150"
              type="text"
              name="complemento"
              onChange={e => setComplemento(e.target.value)}
              value={complemento}
            />
          </WrapInputSmall>
          <WrapInputSmall style={{ position: 'relative', width: '35%', marginLeft: '0px' }}>
            <LabelInput>Bairro <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <Input
              placeholder="Inserir Bairro"
              style={inputStyle}
              maxLength="150"
              type="text"
              name="bairro"
              onChange={e => setBairro(e.target.value)}
              value={bairro}
            />
          </WrapInputSmall>
          <WrapInputSmall style={{ position: 'relative', width: '20%', marginLeft: '10px' }}>
            <LabelInput>CEP <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <InputMask
              mask="99999-999"
              placeholder="Inserir CEP"
              style={inputStyle}
              type="text"
              name="cep"
              onChange={e => setCep(e.target.value)}
              value={cep}
            />
          </WrapInputSmall>
        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInputSmall style={{ position: 'relative', width: '10%', marginLeft: '0px' }}>
            <LabelInput>Estado <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <InputMask
              placeholder="Estado"
              style={inputStyle}
              mask="aa"
              maskChar=""
              type="text"
              name="estado"
              onChange={e => setEstado(e.target.value.toUpperCase())}
              value={estado}
            />
          </WrapInputSmall>
          <WrapInputSmall style={{ position: 'relative', width: '50%', marginLeft: '0px' }}>
            <LabelInput>Cidade <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <Input
              placeholder="Inserir Cidade"
              style={inputStyle}
              maxLength="100"
              type="text"
              name="cidade"
              onChange={e => setCidade(e.target.value)}
              value={cidade}
            />
          </WrapInputSmall>          
          <WrapInputSmall style={{ position: 'relative', width: '30%', marginLeft: '0px' }}>
            <LabelInput>País <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <Input
              placeholder="Inserir País"
              style={inputStyle}
              maxLength="100"
              type="text"
              name="pais"
              onChange={e => setPais(e.target.value)}
              value={pais}
            />
          </WrapInputSmall>
        </Wrap>        
        <Wrap>
          <WrapInput className="wrapInputModal">
            <Items>
              <CancelarBtn type="button" onClick={handleClose3}> Cancelar </CancelarBtn>
              <Button type="button" onClick={() => showModalEndereco.action === 'inserir' ? createEndereco() : updateEndereco(idToModal)}> Salvar </Button>
            </Items>
          </WrapInput>
        </Wrap>
      </ModalBox2>
    </StyledBox>
  );

  const modalTelefone = (
    <div className={'disabeFocus alert-modal'} style={modalStyle}>
      <TitleStyle2 className="TitleStyle2">
        Telefones de {getNome.nome}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleCloseTelefone()}
        />
      </TitleStyle2>
      <ModalBox style={{ overflow: 'auto', height: '400px' }}>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInputSmall style={{ position: 'relative', width: '25%' }}>
            <LabelInput>Tipo <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect
              styles={multSelect}
              placeholder={'Opções...'}
              value={tipoTelefone}      
              onChange={handleTipoTelefone}
              options={tipoTelefoneOptions}        
            />
          </WrapInputSmall>
          <WrapInputSmall style={{ position: 'relative', width: '8%', marginLeft: '0px' }}>
            <LabelInput>DDI <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <InputMask
              mask="99999"
              maskChar=""
              //alwaysShowMask="true"
              placeholder="DDI"
              style={inputStyle}
              type="text"
              name="ddi"
              onChange={e => setDDI(e.target.value)}
              value={ddi}
            />
          </WrapInputSmall>
          <WrapInputSmall style={{ position: 'relative', width: '8%', marginLeft: '0px' }}>
            <LabelInput>DDD <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <InputMask
              mask="99999"
              maskChar=""
              //alwaysShowMask="true"
              placeholder="DDD"
              style={inputStyle}
              type="text"
              name="ddd"
              onChange={e => setDDD(e.target.value)}
              value={ddd}
            />
          </WrapInputSmall>
          <WrapInputSmall style={{ position: 'relative', width: '40%', marginLeft: '0px' }}>
            <LabelInput>Número <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <TelefoneBrasileiroInput
              placeholder="Número"
              style={inputStyle}
              type="text"
              name="numeroFone"
              onChange={e => setNumeroTelefone(e.target.value)}
              value={numeroTelefone}
            />
          </WrapInputSmall>
        </Wrap>
        <Wrap>
          <TableContainer className={classes.containerModal}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headColModal} align="center">Tipo</TableCell>
                  <TableCell className={classes.headColModal} align="center">Telefone</TableCell>
                  <TableCell className={classes.headColModal} align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ width: '100%', backgroundColor: 'white' }}>
                {
                  tableTelefones && tableTelefones.map((row, index) => {
                    return (
                      <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                        <TableCell align="center">
                          {row.tipo}
                        </TableCell>
                        <TableCell align="center">
                          {
                            row.ddi + " - " + row.ddd + " - " + row.numero
                          }
                        </TableCell>
                        <TableCell align="center">
                          <FiEdit
                            style={{ cursor: "pointer" }}
                            color={colors.primary}
                            size="20"
                            title="Editar"
                            onClick={() => editTelefone(row, 'update')}
                          />        
                        
                          <AiTwotoneDelete
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                            color={tint(0.1, colors.error)}
                            size="20"
                            title="Remover"
                            onClick={() => deleteTelefone(row.id)}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Wrap>
        <Wrap>
          <WrapInput className="wrapInputModal">
            <Items>
              <CancelarBtn className='widthButton' type="button" onClick={handleCloseTelefone}> Sair </CancelarBtn>
              <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' type="button" onClick={() => clearTelefone()}>&nbsp;Novo<AddIcon></AddIcon></Button>
              <Button className='widthButton' type="button" onClick={() => saveTelefone()}> Salvar </Button>
            </Items>
          </WrapInput>
        </Wrap>
      </ModalBox>
    </div>
  );

  const modalUsuario = (
    <div className={'disabeFocus alert-modal'} style={modalStyle}>
      <TitleStyle2 className="TitleStyle2">
        Usuário de {getNome.nome}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleCloseUsuario()}
        />
      </TitleStyle2>
      <ModalBox style={{ overflow: 'auto', height: '300px'}}>
        <Wrap style={{ position: 'relative', padding: '3px' }}>
          <WrapInputSmall style={{ position: 'relative', width: '70%', marginLeft: '0px' }}>
            <LabelInput>Login <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <Input
              placeholder="Inserir Login"
              style={inputStyle}
              maxLength="150"
              type="text"
              name="login"
              onChange={e => setLogin(e.target.value)}
              value={login}
            />
          </WrapInputSmall>
          </Wrap>
          <Wrap style={{ position: 'relative', padding: '3px' }}>
          <WrapInputSmall style={{ position: 'relative', width: '70%', marginLeft: '0px' }}>
            <LabelInput>Senha <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <Input
              placeholder="Inserir Senha"
              style={inputStyle}
              maxLength="150"
              type="password"
              name="password"
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
          </WrapInputSmall>
          </Wrap>
          <Wrap style={{ position: 'relative', padding: '3px' }}>
          <WrapInputSmall style={{ position: 'relative', width: '70%' }}>
            <LabelInput>Perfil <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect
              styles={multSelect}
              placeholder={'Opções...'}
              value={perfil}      
              onChange={handlePerfil}
              options={perfilOptions.map((item) => { return { value: item.id, label: item.nome}; })}
            />
          </WrapInputSmall>
         </Wrap>
        <Wrap>
          <WrapInput className="wrapInputModal">
            <Items>
              <CancelarBtn className='widthButton' type="button" onClick={handleCloseUsuario}> Sair </CancelarBtn>
              <Button className='widthButton' type="button" onClick={() => saveUsuario()}> Salvar </Button>
            </Items>
          </WrapInput>
        </Wrap>
      </ModalBox>
    </div>
  );


  return (
    <TableContainer>
       <Modal open={enderecoDialog}
        onClose={handleClose2}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalEndereco}
      </Modal>
      <Modal open={showModalEndereco.show}
            onClose={handleClose3}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyEndereco}
      </Modal>
      <Modal open={telefoneDialog}
        onClose={handleCloseTelefone}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalTelefone}
      </Modal>
      <Modal open={usuarioDialog}
        onClose={handleCloseUsuario}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalUsuario}
      </Modal>
      <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">NOME</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">APELIDO</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">CPF</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">TIPO</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">STATUS</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">AÇÕES</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ width: '100%' }}>
          {
            pessoas && pessoas.map((row, index) => {
              return (
                <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.nome}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.apelido}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.cpf}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.tipo}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{(row.status==="A"?"Ativo":"Inativo")}</TableCell>
                  <TableCell
                    style={{ color: textTableColor, border: 'none', display: 'flex', justifyContent: 'center' }}
                    align="center">
                    <AiOutlineHome
                      onClick={() => openModalEndereco(row, row.id)}
                      style={{ cursor: "pointer", marginLeft: '5px' }}
                      color={colors.primary}
                      size="22"
                      title="Endereços"
                    />
                    <AiOutlinePhone
                      onClick={() => openModalTelefone(row, row.id)}
                      style={{ cursor: "pointer", marginLeft: '5px' }}
                      color={colors.primary}
                      size="22"
                      title="Telefones"
                    />
                    <AiOutlineIdcard
                      onClick={() => openModal(row, 'update')}
                      style={{ cursor: "pointer", marginLeft: '5px'  }}
                      color={colors.primary}
                      size="22"
                      title="Editar"
                    />
                    { 
                      (
                        nomePerfil.toUpperCase() ==="ADMINISTRADOR"
                        ?<AiOutlineUser
                              onClick={() => openModalUsuario(row, 'update')}
                              style={{ cursor: "pointer", marginLeft: '5px'  }}
                              color={colors.primary}
                              size="22"
                              title="Usuário"
                          />
                        :""
                      )
                    }                     
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer >
  );
}