import { apiNoAuth, keySession, keyUserAccess } from './axios'
import {isSafari} from 'react-device-detect';
import { format, getHours, getMinutes, getSeconds } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export async function LoginUser(password, user) {

  if (!user || !password)
    return { message: 'Preencha todos os campos.', success: false }
  else
    try {
      let { data } = await apiNoAuth.post('/login', { user, password })
      if (data.success) {
        const { token, authuser,idUsuario,idPessoa,nmPessoa,perfil,funcionalidades } = data
        await localStorage.setItem(keySession, JSON.stringify({ token, authuser,idUsuario,idPessoa,nmPessoa,perfil,funcionalidades}))
        return { message: data.message, success: true }
      } else
        return { message: data.message, success: false }
    } catch (error) {
      // alert(JSON.stringify(error))
      console.log(error)
      return error
    }
}

export const GetSessionUser = () => {
  try {
    return JSON.parse(localStorage.getItem(keySession))
  } catch (error) {
    console.log(error)
    return { errorMessage: 'Não foi possivel ler a sessão' }
  }
}

export function Logout() {
  try {
    localStorage.removeItem(keySession)
    localStorage.removeItem(keyUserAccess)
    window.location = "/login"
    return true
  } catch (error) {
    window.location = "/login"
    console.log(error)
    return true
  }
}

export const isAuthenticated = () => {
  if (localStorage.getItem(keySession)) {
    return true
  } else {
    return false
  }

}

export const GetUserAccess = () => {
  if (localStorage.getItem(keyUserAccess)) {
    return JSON.parse(localStorage.getItem(keyUserAccess))
  }
  return false
}

export const getDevice = () => {
  
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    || isSafari
  ) {
    return true; // está utilizando celular
  }
  else {
    return false; // não é celular
  }
}

export const MyData = (date) => {
  let dia = format(date, "iiii", { locale: ptBR })
  if (dia === 'segunda' || dia === 'terça' || dia === 'quarta' || dia === 'quinta' || dia === 'sexta') {
    dia = dia + '-' + 'feira'
  }
  let data = format(date, 'dd/MM/yyyy', { locale: ptBR }) + ' ';
  let regDate = format(date, 'yyyy-MM-dd', { locale: ptBR }) + ' ';
  let hora = getHours(date)
  if (parseInt(hora) < 10) {
    hora = '0' + hora
  }
  let minutos = getMinutes(date)
  if (parseInt(minutos) < 10) {
    minutos = '0' + minutos
  }

  let segundos = getSeconds(date)
  if (parseInt(segundos) < 10) {
    segundos = '0' + segundos
  }

  let horas = hora + ":" + minutos
  let horas2 = hora + ":" + minutos + ":" + segundos
  return { dia: dia.toUpperCase(), data: data, horas: horas, minutos, segundos, horas2, regDate }
}