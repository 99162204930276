import React, { useEffect, useState } from 'react';
import { Container, Form, Title,FormInputs, ModalBox2, TitleStyle2, Items} from './styles.js';
import bg from '../../assets/fundo_web.png';
import Header from '../../components/Header';
import {GetSessionUser, MyData} from '../../services/helpers';
import { useShowModal } from "../../contexts/SharedContext";
import { Wrap, WrapInput } from "../../components/Wrap";
import { modalClose, StyledBox,} from "../../components/Modal";
import CancelIcon from "@material-ui/icons/Cancel";
import { Input, LabelInput, inputStyle} from "../../components/Input";
import { Button, CancelarBtn } from "../../components/Button";
import { Modal } from "@material-ui/core";
import { AiOutlineEdit } from "react-icons/ai";
import {  ERRORMESSAGE,  SUCCESSMESSAGE, INFOMESSAGE} from "../../utils/types-messages";
import MessageAlert from "../../components/MessageAlert";
import { useSnackbar } from "notistack";
import { api } from "../../services/axios";

export default function Home(props) {

  const [nomePessoa, setNomePessoa] = useState(null);
  const [myData] = useState(MyData(new Date()));
  const [nomePerfil,setNomePerfil] = useState(null);
  const { showModalGeral, setShowModalGeral } = useShowModal();
  const [senhaAtual, setSenhaAtual] = useState(null);
  const [novaSenha, setNovaSenha] = useState(null);
  const [confirmaNovaSenha, setConfirmaNovaSenha] = useState(null);
  const [idUsuarioLogado, setIdUsuarioLogado] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loadDialog, setLoadDialog] = useState(false);

  const GetUserSession = () => {
    const {nmPessoa,perfil,idUsuario} = GetSessionUser();
    setNomePessoa(nmPessoa);
    setNomePerfil(perfil);
    setIdUsuarioLogado(idUsuario);
  }

  const handleClose = () => {
    setShowModalGeral({ show: false});
  };

  useEffect(() => {
    GetUserSession();
  }, []);

  function openModal() {
    
      setShowModalGeral({
        show: true,
        title: "Alterar a Senha"
      });    
  }

  async function alterarSenha() {
    const key = enqueueSnackbar("Alterando a senha...", INFOMESSAGE);

    try {
      const valores = {
        oldPassword: senhaAtual,
        newPassword: novaSenha,
        renewPassword: confirmaNovaSenha
      };
      
      setLoadDialog(true);
      const { data } = await api.put("/usuario/changepassword/" + idUsuarioLogado, valores);
      if (data.message != null) throw { message: data.message };
      enqueueSnackbar(
        <MessageAlert message={"Senha alterada com sucesso!"} />,
        SUCCESSMESSAGE
      );
      setShowModalGeral({ show: false });
      clearInclude();
      setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  function clearInclude() {
    setSenhaAtual(null);
    setNovaSenha(null);
    setConfirmaNovaSenha(null);
  }

  const body = (
    <StyledBox className={"disableFocus alert-modal"}>
      <TitleStyle2>
        {showModalGeral.title}
        <CancelIcon
          style={modalClose}
          size="20"
          title="Fechar"
          onClick={() => handleClose()}
        />
      </TitleStyle2>
      <ModalBox2>
        <Wrap>
          <WrapInput style={{ position: "relative", width: "10%" }}>
            <LabelInput>
              Senha atual <span style={{ color: "#FF0000" }}>*</span>
            </LabelInput>
            <Input
              placeholder="Insira a senha atual"
              style={inputStyle}
              maxLength="255"
              type="password"
              name="senhaAtual"
              onChange={(e) =>setSenhaAtual(e.target.value)}
              value={senhaAtual}
            />
          </WrapInput>
        </Wrap>
        <Wrap>
          <WrapInput style={{ position: "relative", width: "10%" }}>
            <LabelInput>
              Nova senha <span style={{ color: "#FF0000" }}>*</span>
            </LabelInput>
            <Input
              placeholder="Insira a nova senha"
              style={inputStyle}
              maxLength="255"
              type="password"
              name="novaSenha"
              onChange={(e) =>setNovaSenha(e.target.value)}
              value={novaSenha}
            />
          </WrapInput>
        </Wrap>
        <Wrap>
          <WrapInput style={{ position: "relative", width: "10%" }}>
            <LabelInput>
              Confirme a nova senha <span style={{ color: "#FF0000" }}>*</span>
            </LabelInput>
            <Input
              placeholder="Repita a nova senha"
              style={inputStyle}
              maxLength="255"
              type="password"
              name="confirmaNovaSenha"
              onChange={(e) =>setConfirmaNovaSenha(e.target.value)}
              value={confirmaNovaSenha}
            />
          </WrapInput>
        </Wrap>
        <Wrap>
          <WrapInput className="wrapInputModal">
            <Items>
              <CancelarBtn type="button" onClick={handleClose}>
                {" "}
                Cancelar{" "}
              </CancelarBtn>
              <Button
                type="button"
                onClick={() =>alterarSenha()}                    
              >
                {" "}
                Salvar{" "}
              </Button>
            </Items>
          </WrapInput>
        </Wrap>
      </ModalBox2>
    </StyledBox>
  );

  return (
    <Container bg={bg} >
     <Header/>
     <Modal
        open={showModalGeral.show}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
     <Form>
      <FormInputs>
      <Title> {myData.dia.substring(0,1).toUpperCase() + myData.dia.substring(1).toLocaleLowerCase() + ", dia " + myData.data} </Title>
      <Title> Seja Bem-Vindo(a): {nomePessoa} </Title>
      <Title> Perfil de Acesso: {nomePerfil} </Title>
      <Title onClick={() => openModal()}> Alterar a senha:
          <AiOutlineEdit
            onClick={() => openModal()}
            style={{ cursor: "pointer", marginLeft: '5px'  }}
            //color={colors.primary}
            size="22"
            title="Editar"
          /> 
       </Title>

      
      </FormInputs>
     </Form>
    </Container>
  );
}