import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './app/index';
import { LoadingProvider, MenuProvider, SharedProvider, ThemeProvider } from './contexts/index'
import { AiOutlineCopyright } from "react-icons/ai";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <ThemeProvider>
        <LoadingProvider>
            <SharedProvider>
                    <App />
                    <div style={{ color: "#FFFFFF", textAlign: "center" }}>
                        <AiOutlineCopyright></AiOutlineCopyright> {process.env.REACT_APP_ANO} - {process.env.REACT_APP_EMPRESA} | Todos os direitos reservados - Versão {process.env.REACT_APP_VERSAO + ' - ' + process.env.REACT_APP_AMBIENTE}
                    </div>
            </SharedProvider>
        </LoadingProvider>        
    </ThemeProvider>
);

