import React, { useState, useContext } from 'react'
import { Container, TitleStyle2} from './styles'
import { Wrap, WrapInput, WrapList } from '../../components/Wrap'
import { Input, LabelInput, inputStyle, multSelect } from '../../components/Input';
import { SearchBox, IconeSearch, ItemsSearch } from "../../components/Search";
import { Button } from "../../components/Button";
import { MySelect} from "../../components/InputSelect";
import { api } from '../../services/axios'
import { HeaderList, BodyList } from '../../components/TabelaCadastro';
import { Modal, CircularProgress } from "@material-ui/core";
import { INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from '../../utils/types-messages'
import { useSnackbar } from 'notistack';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import InputMask from 'react-input-mask';
import Header from "../../components/Header"
import { ThemeContext } from 'styled-components';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '../../components/SharedComponents/table';
import { makeStyles } from '@material-ui/core/styles';
import { shade } from 'polished';
import { BsMortarboard, BsUniversalAccess } from "react-icons/bs";
import MessageAlert from '../../components/MessageAlert'

export default function RelatoriosPessoas(props){

  const { colors, textTableColor } = useContext(ThemeContext);
  const useStyles = makeStyles({
    wrapBtnModal: {
      alignItems: 'flex-start'
    },
    containerModal: {
      boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
      borderRadius: '0'
    },
    headColModal: {
      backgroundColor: colors.surface,
      textTransform: 'uppercase',
      borderRadius: '0',
      fontSize: '14px'
    }
  });
  const [pessoas, setPessoas] = useState([]);
  const [nomePesquisa, setNomePesquisa] = useState('');
  const [cpfPesquisa, setCpfPesquisa] = useState('');
  const [statusParaFiltro, setStatusParaFiltro] = useState(true);
  const [tipoParaFiltro, setTipoParaFiltro] = useState(true);
  const [loadDialog, setLoadDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();  

  const handleStateFilter = (item) => {
      setStatusParaFiltro(item);
  };

  const handleTypeFilter = (item) => {
      setTipoParaFiltro(item);
  };

  const modalLoad = (
      <div className={'disabeFocus alert-modal'}>
          <WrapInput className="alertText" style={{ alignItems: 'center' }}>
          <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
          </WrapInput>
      </div>
  );

  async function fetchSearch(showSnack=true) {

    if (showSnack) {const key = enqueueSnackbar('Pesquisando Pessoas ...', INFOMESSAGE);};
    try {
      let params = ''
      if (nomePesquisa !== '') params += `&nome=${nomePesquisa}`
      if (cpfPesquisa !== '') params += `&cpf=${cpfPesquisa}`

      if ((tipoParaFiltro.value !== '') && (tipoParaFiltro.value !== undefined)){
        params += `&tipo=${tipoParaFiltro.value}`
      }

      if ((statusParaFiltro.value !== '') && (statusParaFiltro.value !== undefined)){
        params += `&status=${statusParaFiltro.value}`
      }
      setLoadDialog(true);

      const { data } = await api.get(`pessoa?` + params);
      setPessoas(data);
      setLoadDialog(false);
    } catch (error) {
      enqueueSnackbar("Erro ao buscar Pessoas", ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  function clearSearch() {
      setStatusParaFiltro({ value: '', label: 'Selecione...' });
      setTipoParaFiltro({ value: '', label: 'Selecione...' });
      setNomePesquisa('');
      setCpfPesquisa('');
      setPessoas([]);
  }

  async function gerarPDF(idPessoa, tipo) {
    setLoadDialog(true);
    let temp = [];    
    try {
      if (tipo === 'formativo'){
        temp = await api.get("/relatorios/percursoformativo/" + idPessoa);
      } else {
        temp = await api.get("/relatorios/percursovoluntariado/" + idPessoa);
      }
      const { config } = temp
      const { data } = temp      
      if (data.success) {
        const { path } = data;
        console.log(path)
        var url = config.baseURL + path;
        openURL(url);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
    }
    setLoadDialog(false);
  }

  function openURL(params) {
    var link = document.createElement('a');
    link.href = params;
    //link.click();
    window.open(link, '_blank');
  }

  return (
        <Container>
        <Header/>
        <SearchBox style={{ margin: '20px 0 20px 0' }}>
          <TitleStyle2>Relatório de Pessoas</TitleStyle2>
          <Wrap>
            <WrapInput style={{ width: '10%', paddingRight: '5px' }}> <LabelInput> Nome</LabelInput>
              <Input
                //placeholder="Pesquisar Nome Completo"
                style={inputStyle}
                maxLength="300"
                type="text"
                name="nomePesquisa"
                onChange={e => setNomePesquisa(e.target.value)}
                value={nomePesquisa}
              />
            </WrapInput>
            <WrapInput style={{ width: '5%', paddingRight: '10px' }}>
              <LabelInput> CPF </LabelInput>
              <InputMask
                mask="999.999.999-99"
                style={inputStyle}
                type="text"
                name="cpfPesquisa"
                onChange={e => setCpfPesquisa(e.target.value)}
                value={cpfPesquisa}
              />
            </WrapInput>
            <WrapInput style={{ width: '10%', paddingRight: '0px' }}>
              <LabelInput> Tipo </LabelInput>
              <MySelect
                styles={multSelect}
                placeholder="Selecione..."
                onChange={handleTypeFilter}
                options={[{ value: 'Amigo', label: 'Amigo' }, { value: 'Aprendiz', label: 'Aprendiz' }, { value: 'Membro Iniciante', label: 'Membro Iniciante' }, { value: 'Membro Efetivo', label: 'Membro Efetivo' }]}
                value={tipoParaFiltro}
              />
            </WrapInput>
            <WrapInput style={{ width: '5%', paddingRight: '0px' }}>
              <LabelInput> Status </LabelInput>
              <MySelect
                styles={multSelect}
                placeholder="Selecione..."
                onChange={handleStateFilter}
                options={[{ value: 'A', label: 'Ativo' }, { value: 'I', label: 'Inativo' }]}
                value={statusParaFiltro}
              />
            </WrapInput>
            <WrapInput style={{ marginTop: '10px' }}>
              <ItemsSearch>
                <IconeSearch size={25} title="Pesquisar" onClick={() => fetchSearch(true)} />
                <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
              </ItemsSearch>
            </WrapInput>
          </Wrap>
        </SearchBox>
        <WrapList>
          <HeaderList>
            <span style={{ marginLeft: '20px' }}>PESSOAS  </span>
          </HeaderList>
          {
            <BodyList>
                <TableContainer>
                    <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
                        <TableHead>
                        <TableRow>
                            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">NOME</TableCell>
                            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">APELIDO</TableCell>
                            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">TIPO</TableCell>
                            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">STATUS</TableCell>
                            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">RELATÓRIOS</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody style={{ width: '100%' }}>
                        {
                            pessoas && pessoas.map((row, index) => {
                            return (
                                <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.nome}</TableCell>
                                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.apelido}</TableCell>
                                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.tipo}</TableCell>
                                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{(row.status==="A"?"Ativo":"Inativo")}</TableCell>
                                  <TableCell style={{ color: textTableColor, border: 'none', display: 'flex', justifyContent: 'center' }} align="center">
                                    <BsMortarboard
                                      onClick={() => gerarPDF(row.id, 'formativo')}
                                      style={{ cursor: "pointer", marginLeft: '5px' }}
                                      color={colors.primary}
                                      size="22"
                                      title="Percurso Formativo"
                                    />  
                                    <BsUniversalAccess
                                      onClick={() => gerarPDF(row.id, 'voluntariado')}
                                      style={{ cursor: "pointer", marginLeft: '5px' }}
                                      color={colors.primary}
                                      size="22"
                                      title="Percurso Voluntariado"
                                    />  
                                  </TableCell>
                                </TableRow>
                            )
                            })
                        }
                        </TableBody>
                    </Table>
                </TableContainer >
              
            </BodyList>  
          }
        </WrapList>
        <Modal open={loadDialog}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {modalLoad}
        </Modal>
      </Container>
    )
}