import React, { useState, useContext, useEffect } from 'react';
import { Wrap, WrapInput, WrapList } from '../../components/Wrap';
import { Input, LabelInput, inputStyle,multSelect } from '../../components/Input';
import { SearchBox, IconeSearch, ItemsSearch } from "../../components/Search";
import { Button, CancelarBtn } from "../../components/Button";
import { Container, TitleStyle2,Items} from './styles'
import { HeaderList, BodyList } from '../../components/TabelaCadastro';
import { Modal, CircularProgress } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '../../components/SharedComponents/table';
import { shade } from 'polished'
import { AiOutlineDelete, AiOutlineEdit,AiOutlineDesktop,AiTwotoneDelete } from "react-icons/ai";
import { ThemeContext } from 'styled-components';
import { ERRORMESSAGE, SUCCESSMESSAGE, INFOMESSAGE } from '../../utils/types-messages';
import { useSnackbar } from 'notistack';
import { api } from '../../services/axios';
import { makeStyles } from '@material-ui/core/styles';
import { modalClose, ModalBox, StyledBox } from '../../components/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import MessageAlert from '../../components/MessageAlert';
import { useShowModal } from '../../contexts/SharedContext'
import { MySelect } from "../../components/InputSelect";
import Header from "../../components/Header";
import { tint } from "polished";

export default function CadastroPerfis(props){
    const { enqueueSnackbar } = useSnackbar();
    const { colors, textTableColor } = useContext(ThemeContext);
    const [perfis, setPerfis] = useState([]);
    const [nomeConsulta, setNomeConsulta] = useState('');
    const [statusConsulta, setStatusConsulta] = useState('');
    const [loadDialog, setLoadDialog] = useState(false);
    const [idToModal, setIdToModal] = useState();
    const { setShowModalGeral, showModalGeral } = useShowModal();
    const { setShowModalInclusao, showModalInclusao } = useShowModal();
    const [nome, setNome] = useState(null);
    const [status, setStatus] = useState(null);
    const [funcionalidade, setFuncionalidade] = useState(null);
    const [funcionalidadesOptions, setFuncionalidadesOptions] = useState([]);
    const [tableFuncionalidades, setTableFuncionalidades] = useState([]);
    const [idPerfil, setIdPerfil] = useState(null);

    const useStyles = makeStyles({
        wrapBtnModal: {
          alignItems: 'flex-start'
        },
        containerModal: {
          boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
          borderRadius: '0'
        },
        headColModal: {
          backgroundColor: colors.surface,
          textTransform: 'uppercase',
          borderRadius: '0',
          fontSize: '14px'
        }
    });
    const classes = useStyles();

    const handleFuncionalidade = (item) => {
        setFuncionalidade(item);
    }

    const getFuncionalidadesParaPerfil = async (idPerfil) => {
        try {
            setLoadDialog(true);
            const { data } = await api.get('/perfilfuncionalidade/funcionalidadesparaperfil/'+idPerfil);
            setFuncionalidadesOptions(data);
            setLoadDialog(false);
        } catch (err) {
            enqueueSnackbar("Erro ao buscar dados iniciais", ERRORMESSAGE);
            setLoadDialog(false);
        }
    }

    const getFuncionalidades = async (idPerfil) => {
        try {
            setLoadDialog(true);
            const { data } = await api.get('/perfilfuncionalidade/'+idPerfil);
            setTableFuncionalidades(data);
            setLoadDialog(false);
        } catch (err) {
            enqueueSnackbar("Erro ao buscar dados iniciais", ERRORMESSAGE);
            setLoadDialog(false);
        }
    }

    function openModalPerfilFuncionalidade(item) {
        setIdPerfil(item.id);
        getFuncionalidadesParaPerfil(item.id);
        getFuncionalidades(item.id);
        setShowModalInclusao({ show: true, title: 'FUNCIONALIDADES DO PERFIL', action: 'editar', item: item });          
    }
    

    const statusOptions = [
      { value: "A", label: "Ativo" },
      { value: "I", label: "Inativo" }
    ]

    const handleCloseFuncionalidades = () => {
        setShowModalInclusao({ show: false });
    };

    const handleStatus = (item) => {
      setStatus(item);
    }

    const handleStatusConsulta = (item) => {
      setStatusConsulta(item);
    }

    async function fetchSearch(showSnack=true) {
        if (showSnack) {const key = enqueueSnackbar('Pesquisando Perfis ...', INFOMESSAGE);};
        try {
            let params = ''
            if (nomeConsulta !== '') params += `&nome=${nomeConsulta}`
            if ((statusConsulta !== null) && (statusConsulta.value !== undefined) && (statusConsulta.value !== '')){
              params += `&status=${statusConsulta.value}`
            }
            setLoadDialog(true);
            const { data } = await api.get(`perfil?` + params);
            setPerfis(data);
            setLoadDialog(false);
        } catch (error) {
            enqueueSnackbar("Erro ao buscar Perfis", ERRORMESSAGE);
            setLoadDialog(false);
        }
    }

    function clearSearch() {
        setPerfis([]);
        setNomeConsulta('');
        setStatusConsulta([]);
    }

    function openModal(action, item) {
        clearInclude();
        if (action === "insert") {
            setShowModalGeral({ show: true, title: 'INCLUIR PERFIL', action: 'inserir' });
        }
        else {
            setShowModalGeral({ show: true, title: 'ALTERAR PERFIL', action: 'editar', item: item });
        }
            
    }

    function clearInclude() {
        setNome(null);
        setStatus([]);
        
    }

    const handleClose = () => {
        setShowModalGeral({ show: false });
      };
    
    async function savePerfilFuncionalidade() {
        const key = enqueueSnackbar('Cadastrando Funcionalidade ...', INFOMESSAGE);
        try {
            const valoresInseridos = {
                idPerfil: idPerfil,
                idFuncionalidade: funcionalidade.value
            };
            setLoadDialog(true);
            const { data } = await api.post("/perfilfuncionalidade/", valoresInseridos);
            if (data.message!=null) throw {message: data.message}
            enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
            setTableFuncionalidades([]);
            setFuncionalidade([]);
            getFuncionalidadesParaPerfil(idPerfil);
            getFuncionalidades(idPerfil);
            setLoadDialog(false);
        } catch (err) {
            enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
            setLoadDialog(false);
        }
    }

    async function deletePerfilFuncionalidade(idPerfil,idFuncionalidade) {
        try {
            var resposta = window.confirm("Deseja remover esse registro?");
            if (resposta) {
              const key = enqueueSnackbar('Excluindo Funcionalidade ...', INFOMESSAGE);
              const { data } = await api.delete("/perfilfuncionalidade/" + idPerfil + "/" + idFuncionalidade);
              if ((data.success !== undefined) && (!data.success))
              {
                throw new Error(data.message)
              }
              enqueueSnackbar(<MessageAlert message={"Funcionalidade excluída do perfil com sucesso!"} />, SUCCESSMESSAGE);
              fetchSearch(false);
              getFuncionalidadesParaPerfil(idPerfil);
              getFuncionalidades(idPerfil);
            }
          } catch (err) {
            enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
          }
    }
    async function createRegister() {
        const key = enqueueSnackbar('Cadastrando Perfil ...', INFOMESSAGE);        
        try {
    
          const valoresInseridos = {
            nome: nome,
            status: status.value
          };
    
          setLoadDialog(true);
          const { data } = await api.post("/perfil/", valoresInseridos);
          if (data.message!=null) throw {message: data.message}
          enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
          setShowModalGeral({ show: false });
          clearInclude();
          fetchSearch(false);
          setLoadDialog(false);
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
          setLoadDialog(false);
        }
      };
    
      async function editRegister(id) {    
        const key = enqueueSnackbar('Alterando Perfil ...', INFOMESSAGE);    
        try {
    
          const valoresEditados = {
            nome: nome,
            status: status.value
          };
    
          setLoadDialog(true);
          const { data } = await api.put("/perfil/" + id, valoresEditados);
          if (data.message!=null) throw {message: data.message}
          enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
          setShowModalGeral({ show: false });
          clearInclude();
          fetchSearch(false);
          setLoadDialog(false);
    
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
          setLoadDialog(false);
        }
      };

      useEffect(() => {
        if (showModalGeral.action === 'editar') {
            setIdToModal(showModalGeral.item.id);
            setNome(showModalGeral.item.nome);
            if (showModalGeral.item.status === "A") {
              setStatus({ value: "A", label: "Ativo" });
            } else {
              setStatus({ value: "I", label: "Inativo" });
            }
        }
      }, [showModalGeral.action])


    async function deleteRegister(id) {
        try {
          var resposta = window.confirm("Deseja remover esse registro?");
          if (resposta) {
            const key = enqueueSnackbar('Excluindo Perfil ...', INFOMESSAGE);
            const { data } = await api.delete("/perfil/" + id);
            if ((data.success !== undefined) && (!data.success))
            {
              throw new Error(data.message)
            }
            enqueueSnackbar(<MessageAlert message={"Perfil excluído com sucesso!"} />, SUCCESSMESSAGE);
            fetchSearch(false);
          }
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
        }
    };
    

    const bodyModal = (
        <StyledBox className={'disableFocus alert-modal'}>
          <TitleStyle2>
            {showModalGeral.title}
            <CancelIcon
              style={modalClose}
              color={colors.error}
              size="20"
              title="Fechar"
              onClick={() => handleClose()}
            />
          </TitleStyle2>
          <ModalBox>
            <Wrap>
              <WrapInput style={{ position: 'relative', width: '30%'}}>
                <LabelInput>Nome <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <Input
                  placeholder="Inserir Nome"
                  style={inputStyle}
                  maxLength="100"
                  type="text"
                  name="nome"
                  onChange={e => setNome(e.target.value)}
                  value={nome}
                />
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput style={{ position: 'relative', width: '30%'}}>
                <LabelInput>Status <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <MySelect
                  styles={multSelect}
                  placeholder={'Selecione o Status'}
                  value={status}
                  isClearable={true}
                  isSearchable={true}
                  onChange={handleStatus}
                  options={statusOptions}
                />
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput className="wrapInputModal">
                <Items>
                  <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
                  <Button type="button" onClick={() => showModalGeral.action === 'inserir' ? createRegister() : editRegister(idToModal)} > Salvar </Button>
                </Items>
              </WrapInput>
            </Wrap>
          </ModalBox>
        </StyledBox>
      );

      const bodyFuncionalidades = (
        <StyledBox className={'disableFocus alert-modal'}>
          <TitleStyle2>
            {showModalGeral.title}
            <CancelIcon
              style={modalClose}
              color={colors.error}
              size="20"
              title="Fechar"
              onClick={() => handleCloseFuncionalidades()}
            />
          </TitleStyle2>
          <ModalBox>
            <Wrap>
              <WrapInput className="wrapInputModal">
                  <LabelInput>Funcionalidades <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                  <MySelect
                    styles={multSelect}
                    placeholder={'Opções...'}
                    value={funcionalidade}
                    isClearable={true}
                    isSearchable={true}
                    onChange={handleFuncionalidade}
                    options={funcionalidadesOptions.map((item) => { return { value: item.id, label: item.nome}; })}
                  />
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput className="wrapInputModal" >
                <Items style={{height: '16px'}}>
                  <CancelarBtn className='widthButton' type="button" onClick={handleCloseFuncionalidades}> Sair </CancelarBtn>
                  <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' type="button" onClick={() => savePerfilFuncionalidade()}>&nbsp;Adicionar<AddIcon></AddIcon></Button>
                </Items>
              </WrapInput>
            </Wrap>
            <WrapList>
              {
                <BodyList style={{ width: '93%', height: '35vh'}}>
                <TableContainer className={classes.containerModal}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.headColModal} align="center">Funcionalidade</TableCell>
                        <TableCell className={classes.headColModal} align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ width: '90%', backgroundColor: 'white' }}>
                      {
                        tableFuncionalidades && tableFuncionalidades.map((row, index) => {
                          return (
                            <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                              <TableCell align="center">
                                {row.nome}
                              </TableCell>
                              <TableCell align="center">
                                <AiTwotoneDelete
                                  style={{ cursor: "pointer", marginLeft: "5px" }}
                                  color={tint(0.1, colors.error)}
                                  size="20"
                                  title="Remover"
                                  onClick={() => deletePerfilFuncionalidade(row.idPerfil,row.idFuncionalidade)}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                </BodyList>
                }
            </WrapList>
          </ModalBox>
        </StyledBox>
      );
    

    const modalLoad = (
        <div className={'disabeFocus alert-modal'}>
            <WrapInput className="alertText" style={{ alignItems: 'center' }}>
            <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
            </WrapInput>
        </div>
    );

    return (
        <Container>
          <Header/>
            <Modal open={showModalGeral.show}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {bodyModal}
            </Modal>
            <Modal open={showModalInclusao.show}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {bodyFuncionalidades}
            </Modal>
            <SearchBox style={{ margin: '20px 0 20px 0' }}>
                <TitleStyle2>Pesquisa de Perfis</TitleStyle2>
                <Wrap>
                <WrapInput style={{ width: '20%', paddingRight: '5px' }}> <LabelInput> Nome </LabelInput>
                    <Input
                        placeholder="Filtrar por Nome"
                        style={inputStyle}
                        maxLength="100"
                        type="text"
                        name="nome"
                        onChange={e => setNomeConsulta(e.target.value)}
                        value={nomeConsulta}
                    />
                </WrapInput>
                <WrapInput style={{ width: '10%', paddingRight: '1px' }}> <LabelInput> Status </LabelInput>
                  <MySelect
                    styles={multSelect}
                    placeholder={'Filtrar por Status...'}
                    value={statusConsulta}
                    isClearable={true}
                    isSearchable={true}
                    onChange={handleStatusConsulta}
                    options={statusOptions}
                  />
                </WrapInput>
                <WrapInput style={{ width:'30%', marginTop: '10px' }}>
                    <ItemsSearch>
                    <IconeSearch size={25} title="Pesquisar" onClick={() => fetchSearch(true)} />
                    <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' onClick={() => openModal('insert')}>&nbsp;Novo<AddIcon></AddIcon></Button>
                    <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
                    </ItemsSearch>
                </WrapInput>
                </Wrap>
            </SearchBox>
            <WrapList>
                <HeaderList>
                <span style={{ marginLeft: '20px' }}>PERFIS</span>
                </HeaderList>
                {
                <BodyList>
                    <TableContainer>
                        <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">NOME</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">STATUS</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">AÇÕES</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody style={{ width: '100%' }}>
                            {
                                perfis && perfis.map((row, index) => {
                                return (
                                    <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.nome}</TableCell>                                    
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.status}</TableCell>
                                        <TableCell
                                            style={{ color: textTableColor, border: 'none', display: 'flex', justifyContent: 'center' }}
                                            align="center">
                                            <AiOutlineEdit
                                            onClick={() => openModal('update',row)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="Editar"
                                            />          
                                            <AiOutlineDelete
                                            onClick={() => deleteRegister(row.id)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="excluir"
                                            />                
                                            <AiOutlineDesktop
                                            onClick={() => openModalPerfilFuncionalidade(row)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="Funcionalidades"
                                            />        
                                        </TableCell>
                                    </TableRow>
                                    )
                                    })
                                }
                                </TableBody>
                        </Table>
                    </TableContainer >
 
                </BodyList>  
                }
            </WrapList>
            <Modal open={loadDialog}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {modalLoad}
            </Modal>
        </Container>
    )
}